import { combineActions, createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { Map, List, fromJS } from 'immutable';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import toArray from 'lodash/toArray';

import api from 'api';

const initialState = Map({
  statuses: List(),
  features: Map(),
  actions: Map({
    search: '',
  }),
  sortKey: 'sortOrder',
  sortDirection: 'asc',
  isFetched: false,
  filterStatusId: null,
});

export const reducer = handleActions(
  {
    FEATURES_FETCH_FEATURE_STATUSES_FULFILLED: (state, { payload }) =>
      state.set('statuses', fromJS(payload)),
    FEATURES_FETCH_FEATURES_PENDING: state => state.set('isFetched', false),
    FEATURES_FETCH_FEATURES_REJECTED: state => state.set('isFetched', true),
    FEATURES_FETCH_FEATURES_FULFILLED: (state, { payload }) =>
      state
        .set('features', fromJS(keyBy(payload.map(feature => ({ ...feature, comments: keyBy(feature.comments, 'uuid')})), 'uuid')))
        .set('isFetched', true),
    FEATURES_SAVE_FEATURE_FULFILLED: (state, { payload }) =>
      state.setIn(
        ['features', payload.uuid],
        fromJS({
          ...payload,
          voted: null,
        })
      ),
    [combineActions(
      'FEATURES_FEATURE_VOTE_UP_FULFILLED',
      'FEATURES_FEATURE_VOTE_DOWN_FULFILLED'
    )]: (state, { payload: { uuid, rating, voted } }) =>
      state
        .setIn(['features', uuid, 'rating'], rating)
        .setIn(['features', uuid, 'voted'], voted),
    FEATURES_FEATURE_UPDATE_FULFILLED: (state, { payload }) =>
      state.setIn(['features', payload.uuid], fromJS(payload))
        .setIn(['features', payload.uuid, 'comments'], fromJS(keyBy(payload.comments, 'uuid'))),
    FEATURES_FEATURE_DELETE_FULFILLED: (state, { payload }) =>
      state.deleteIn(['features', payload]),

    FEATURES_SEARCH_FEATURES: (state, { payload }) =>
      state.setIn(['actions', 'search'], payload),
    FEATURES_CHANGE_FEATURE_STATUS_FULFILLED: (
      state,
      { payload: { uuid, status } }
    ) => state.setIn(['features', uuid, 'status'], fromJS(status)),
    FEATURES_CHANGE_SORT_DIRECTION: (
      state,
      { payload: { sortKey, sortDirection } }
    ) => state.set('sortDirection', sortDirection).set('sortKey', sortKey),
    FEATURES_FETCH_FEATURE_WITH_COMMENTS_FULFILLED: (state, { payload }) =>
      state.setIn(
        ['features', payload.uuid],
        fromJS({
          ...omit(payload, 'answer'),
          comments: keyBy(payload.comments, 'uuid'),
        })
      ),
    FEATURES_PUT_COMMENT_FULFILLED: (state, { payload, meta }) =>
      state.setIn(
        ['features', meta.featureUUID, 'comments', payload.uuid],
        fromJS(payload)
      ),
    FEATURES_IS_ANSWERED_FULFILLED: (state, { payload, meta }) =>
      state.setIn(
        ['features', meta.featureUUID, 'comments', payload.uuid],
        fromJS(payload)
      ),
    FEATURES_DELETE_COMMENT_FULFILLED: (state, { payload, meta }) =>
      state.deleteIn(['features', meta.featureUUID, 'comments', payload]),
    FEATURES_SET_FILTER: (state, { payload }) =>
      state.set('filterStatusId', payload),
    FEATURES_CLEAR_FILTER: state => state.set('filterStatusId', null),
    FEATURES_CLEAR_FEATURES: state =>
      state.set('features', Map()).set('isFetched', false),
  },
  initialState
);

// Actions

export const featuresActions = {
  fetchFeatureStatuses: createAction('FEATURES_FETCH_FEATURE_STATUSES', () =>
    api.get('help/featureStatuses')
  ),
  fetchFeatures: createAction('FEATURES_FETCH_FEATURES', uuid =>
    api.get(`help/feedback/page/${uuid}`)
  ),
  clearFeatures: createAction('FEATURES_CLEAR_FEATURES'),
  saveFeature: createAction('FEATURES_SAVE_FEATURE', (uuid, feature) =>
    api.post(`help/feedback/page/${uuid}`, feature)
  ),
  changeFeatureStatus: createAction(
    'FEATURES_CHANGE_FEATURE_STATUS',
    (featureUUID, statusId) =>
      api.put(`help/features/${featureUUID}/status`, {
        status_id: statusId,
      })
  ),
  voteUp: createAction('FEATURES_FEATURE_VOTE_UP', featureId =>
    api.put(`help/features/${featureId}/voteUp`)
  ),
  voteDown: createAction('FEATURES_FEATURE_VOTE_DOWN', featureId =>
    api.put(`help/features/${featureId}/voteDown`)
  ),
  updateFeature: createAction('FEATURES_FEATURE_UPDATE',
    (featureUUID, data) => api.post(`help/features/${featureUUID}`, data)),
  deleteFeature: createAction('FEATURES_FEATURE_DELETE', featureUUID =>
    api.delete(`help/features/${featureUUID}`)
  ),
  searchFeatures: createAction('FEATURES_SEARCH_FEATURES'),
  changeSortDirection: createAction(
    'FEATURES_CHANGE_SORT_DIRECTION',
    (sortKey, sortDirection) => ({
      sortKey,
      sortDirection,
    })
  ),
  fetchFeatureWithComments: createAction(
    'FEATURES_FETCH_FEATURE_WITH_COMMENTS',
    featureUUID => api.get(`help/features/${featureUUID}/comments`)
  ),
  putComment: createAction(
    'FEATURES_PUT_COMMENT',
    (featureUUID, text, isAnswer = false) =>
      api.put(`help/features/${featureUUID}/comment`, ({ text, isAnswer })),
    featureUUID => ({ featureUUID })
  ),
  toggleIsAnswered: createAction(
    'FEATURES_IS_ANSWERED',
    (featureUUID, commentId, isAnswer) =>
      api.put(`help/features/comments/${commentId}/isAnswer`, { isAnswer }),
    featureUUID => ({ featureUUID })
  ),
  deleteComment: createAction(
    'FEATURES_DELETE_COMMENT',
    (featureUUID, commentId) =>
      api.delete(`help/features/comments/${commentId}`),
    featureUUID => ({ featureUUID })
  ),
  setFilter: createAction('FEATURES_SET_FILTER'),
  clearFilter: createAction('FEATURES_CLEAR_FILTER'),
};

const isFetched = createSelector(
  state => state.features.get('isFetched'),
  isFetched => !!isFetched
);

const searchField = createSelector(
  state => state.features.getIn(['actions', 'search']),
  search => search
);

const sortKey = createSelector(
  state => state.features.get('sortKey'),
  sortKey => sortKey
);

const makeFeature = () =>
  createSelector(
    (state, uuid) => state.features.getIn(['features', uuid]),
    feature => {
      if (feature) {
        const response = feature.toJS();
        return { ...response, comments: toArray(response.comments) };
      }
    }
  );

const makeFeatureAnswer = () =>
  createSelector(
    (state, uuid) => state.features.getIn(['features', uuid, 'comments']),
    (comments) => {
      const answer = comments && comments.find(comment => comment.get('is_answer') === 1);
      return answer ? answer.toJS() : null;
    }
  );

const featuresListImmutable = createSelector(
  state => state.features.get('features'),
  features => features.toList()
);

const featuresList = createSelector(
  featuresListImmutable,
  state => state.features.getIn(['actions', 'search']),
  state => state.features.get('sortKey'),
  state => state.features.get('sortDirection'),
  state => state.features.get('filterStatusId'),
  (featuresImmutable, searchField, sortKey, sortDirection, filterStatusId) => {
    const features = featuresImmutable.filter(
      feature =>
        feature
          .get('name')
          .toLowerCase()
          .indexOf(searchField.trim().toLowerCase()) !== -1
    );

    if (filterStatusId) {
      return orderBy(
        filter(features.toJS(), feature => feature.statusId === filterStatusId),
        ['rating'],
        ['desc']
      );
    }

    return orderBy(
      features.toJS(),
      [sortKey, 'rating'],
      [sortDirection, 'desc']
    );
  }
);

const featureStatuses = createSelector(
  state => state.features.get('statuses'),
  statuses => statuses && statuses.toJS()
);

const hasFilter = createSelector(
  state => state.features.get('filterStatusId'),
  filterStatusId => !!filterStatusId
);

const count = createSelector(
  featuresList,
  featuresList => featuresList.length
);

export const featuresSelectors = {
  isFetched,
  makeFeature,
  makeFeatureAnswer,
  featuresList,
  searchField,
  featureStatuses,
  sortKey,
  hasFilter,
  count,
};
