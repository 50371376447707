import React from 'react';
import styled from '@emotion/styled/macro';
import { distanceInWordsToNow } from 'date-fns';
import { IconButton } from '@common/buttons/IconButton';
import { Space } from '../layout/Space';

export const Comment = ({
  className,
  isAnswer,
  isLarge,
  name,
  text,
  time,
  canEdit,
  isAdmin,
  onMarkAnswered,
  onDelete,
  adminActions,
}) => (
  <CommentWrapper isAnswer={isAnswer} isLarge={isLarge} className={className}>
    <Header>
      <Author>
        <AuthorIcon>{name[0]}</AuthorIcon>
        {name}
      </Author>
      {isLarge && time && (
        <Time>
          {distanceInWordsToNow(new Date(time * 1000))} ago
        </Time>
      )}
      {canEdit && (
        <Actions>
          {isAdmin && (
            <>
              {adminActions}
              <IconButton
                icon="faCheckDouble"
                iconSize={18}
                onClick={onMarkAnswered}
              />
            </>
          )}
          <IconButton icon="faTrash" iconSize={18} onClick={onDelete} />
        </Actions>
      )}
    </Header>
    <Text isLarge={isLarge}>{text}</Text>
  </CommentWrapper>
);

const Actions = styled(Space)`
  display: flex;
  margin-left: auto;
`;

const CommentWrapper = styled('article')`
  display: flex;
  padding: 24px;
  font-size: 14px;
  flex-direction: ${({ isLarge }) => (isLarge ? 'column' : 'row')};
  align-items: ${({ isLarge }) => !isLarge && 'center'};
  background: ${({ isAnswer }) => isAnswer && '#F1F4F6'};
  border: ${({ isAnswer }) => !isAnswer && '1px solid #D2D2D2'};
  border-radius: ${({ isAnswer }) => (isAnswer ? 0 : '6px')};
  order: ${({ isAnswer }) => (isAnswer ? 0 : 1)};
  ${({ isAnswer }) =>
    isAnswer &&
    `
    border-left: 5px solid #05C46B;
  `};
`;

const Header = styled('header')`
  display: flex;
  align-items: center;
`;

const Author = styled('span')`
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
  font-weight: 500;
  white-space: nowrap;
  word-break: break-word;
`;

const AuthorIcon = styled('span')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.brand};
  text-transform: uppercase;
  color: white;
  margin-right: 8px;
`;

const Time = styled('time')`
  color: ${({ theme }) => theme.color.lightGrey};
  white-space: nowrap;
`;

const Text = styled('span')`
  word-break: break-word;
  ${({ isLarge }) =>
    isLarge &&
    `
    display: flex;
    width: 100%;
    margin-top: 22px;
  `}
`;
