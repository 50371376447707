import React from 'react';
import styled from '@emotion/styled';

import { mq } from 'themes';
import { Link } from './link/Link';

export const LogoBeyond = () => (
  <LogoWrapper to="landing">the beyond</LogoWrapper>
);

const LogoWrapper = styled(Link)`
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial sans-serif;
  ${mq({
    fontSize: ['28px', '32px'],
  })};
  font-weight: bold;
  color: ${({ theme }) => theme.beyond.black};
  white-space: nowrap;
  line-height: 1.18;
`;