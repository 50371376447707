import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

import { mq } from 'themes';
import { Link } from '@common';
import { LogoBeyond } from '@common/LogoBeyond';

export const PrivacyContainer = () => (
  <React.Fragment>
    <Helmet>
      <title>{'Privacy Policy | The Beyond Feedback'}</title>
    </Helmet>
    <Header>
      <LogoBeyond />
      <List>
        <ListItem>
          <NavLink to="auth.login">Login</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="auth.register">Register</NavLink>
        </ListItem>
      </List>
    </Header>
    <Content>
      <Heading>Privacy Policy</Heading>
      <p>
        Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our websites,  services, mobile applications, and products ("Services").
      </p>
      <p>We store personal information for only as long as we have a reason to keep it.</p>
      <p>We will not use or share your information with anyone except as described in this Privacy Policy.</p>

      <h2>What personal information do we collect from the people that visit our blog, website or app?</h2>
      <p>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address or other details to help you with your experience.</p>
      <p>We may also collect information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
      <p>When you authenticate via a third-party service like Twitter, Facebook, Google or others, we may collect, store, and periodically update the profile information associated with that third-party account.</p>

      <h2>When do we collect information?</h2>
      <p>We collect information from you when you register on our site, subscribe to a newsletter or enter information on our services</p>

      <h2>How do we use your information?</h2>
      <p>We use your data to provide and improve our service. By using our service, you agree to the collection and use of information in accordance with this policy.</p>
      <p>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
      <ul>
        <li>To allow us to better service you in responding to your customer service requests.</li>
        <li>To administer a contest, promotion, survey or other site feature.</li>
        <li>To send periodic emails regarding your order or other products and services.</li>
        <li>To follow up with them after correspondence (live chat, email or phone inquiries)</li>
      </ul>
      <p>You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>

      <h2>Do we use Cookies?</h2>
      <p>Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>

      <h2>We use collected data to:</h2>
      <ul>
        <li>To provide and maintain our Service</li>
        <li>Understand and save user's preferences for future visits.</li>
        <li>To notify you about changes to our Service</li>
        <li>To provide customer support</li>
        <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</li>
      </ul>
      <p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies.If you turn cookies off, some features will be disabled. It won't affect the user's experience that make your site experience more efficient and may not function properly.However, you will still be able to place orders.</p>

      <h2>Customer Data</h2>
      <p>Customer Data may include personal information such as names, and email addresses and not only of your end user customers, potential customers and other users of and visitors to your websites, apps and other services.</p>

      <h2>Disclosure of your information</h2>
      <p>We don’t share your personal information outside the company.</p>
      <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.</p>
      <p>However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>

      <h2>Third-party links</h2>
      <p>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>

      <h2>Security Of Data</h2>
      <p>We use encryption (HTTPS/TLS) to protect data transmitted to and from our site.</p>

      <h2>Modifying your personal information or deleting your account</h2>
      <p>If you have an account in our Service you can access, modify your personal information or delete your account on a Settings page. To protect information from accidental or malicious destruction, we may not immediately delete residual copies from our active servers and may not remove information from our backup systems.</p>

      <h2>Data protection rights</h2>
      <p>In accordance with the European General Data Protection Regulation (GDPR), you have a right of access, correction, and removal of your personal data which you may exercise by sending us an email request to hello@nolt.io. Your requests will be processed within 30 days. We may require that your request is accompanied by a photocopy of proof of identity or authority.</p>

      <h2>Third-party services</h2>
      <p>We use third-party services for hardware, software, networking, storage, and related technology we need to facilitate our Service, to provide the Service on our behalf, to assist us in analytics.</p>
      <p>These third parties have access to your Personal Data:</p>

      <h3>Analytics:</h3>
      <p>We may use third-party Service Providers to monitor and analyze the use of our Service as&nbsp;
        <a href="https://policies.google.com/privacy?hl=en">Google Analytics</a>,
        <a href="https://metrica.yandex.com/about/info/privacy-policy">Yandex Metrika</a>
      </p>

      <h3>Registration and authentication:</h3>
      Google OAuth, Facebook OAuth, Twitter OAuth, Sign in with Apple
      <h3>Integrations:</h3>
      We allow you to link your Beyond account with other applications and services, such as Trello, Slack or Jira.
      We also integrated Facebook Messenger to be in touch with our users <a href="https://www.facebook.com/privacy/explanation">(Facebook Data Policy)</a>.
      <br />
      With these integrations, we share usernames, posts, and comments.

      <h2>How does our site handle Do Not Track signals?</h2>
      <p>We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</p>

      <h2>COPPA (Children Online Privacy Protection Act)</h2>
      <p>When it comes to the collection of personal information from children under the age of 13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.We do not specifically market to children under the age of 13 years old.</p>

      <h2>Changes To This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of the Services after any change in this Privacy Policy will constitute your consent to such change.</p>

      <h2>Contacts</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:team@thebeyond.io">team@thebeyond.io</a></p>
    </Content>
  </React.Fragment>
);

const Header = styled('header')`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  ${mq({
    position: ['relative', 'fixed'],
  })}
`;

const Content = styled('main')`
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial sans-serif;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  line-height: 1.5;
  ${mq({
    padding: ['20px', '100px 20px'],
    maxWidth: ['initial', '700px'],
  })};
  
  h2 {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 14px;
  }
  h3 {
    font-size: 16px;
  }
  p {
    margin: 0 0 16px;
  }
`;

const Heading = styled('h1')`
  font-size: 36px;
  margin-bottom: 32px;
`;

const List = styled('ul')`
  list-style: none;
  justify-content: flex-end;
  ${mq({
    display: ['none', 'flex'],
  })};
`;
const ListItem = styled('li')`
  margin: 0 14px;
`;
const NavLink = styled(Link)`
  color: ${({ theme }) => theme.color.grey};
  font-size: 18px;
  font-weight: bold;
`;