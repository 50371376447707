import React from 'react';
import styled from '@emotion/styled/macro';

export const Card = ({ as, className, children, ...rest }) => (
  <Container as={as} className={className} {...rest}>{children}</Container>
)

const Container = styled('div')`
  border: 1px solid #EEF0F1;
  border-radius: 6px;
  background: white;
`