import { css } from '@emotion/core';

export const globalStyles = theme => css`
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
    color: ${theme.color.black};
    line-height: 1.18;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  img {
    width: 100%;
  }
`;
