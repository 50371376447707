import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { Icon, Menu } from '@common';
import { Dropdown } from '@common/formControls/Dropdown';
import { Button } from '@common/buttons/Button';
import { Chip } from '@common/chips/Chip';
import { useDispatchHook } from '@common/hooks';
import { featuresActions, featuresSelectors } from 'app/features';

const sortList = [
  {
    slug: 'sortOrder',
    name: 'Relevance',
    direction: 'asc',
  },
  {
    slug: 'createdAt',
    name: 'Recent',
    direction: 'desc',
  },
  {
    slug: 'rating',
    name: 'Top',
    direction: 'desc',
  },
];

export const TopNav = () => {
  const sortKey = useSelector(featuresSelectors.sortKey);
  const hasFilter = useSelector(featuresSelectors.hasFilter);
  const count = useSelector(featuresSelectors.count);

  const changeSortDirection = useDispatchHook(featuresActions.changeSortDirection);
  const setFilter = useDispatchHook(featuresActions.setFilter);
  const clearFilter = useDispatchHook(featuresActions.clearFilter);

  const menu = sortList.map((sortValue) => (
    <Menu.Item
      key={sortValue.slug}
      onClick={() => changeSortDirection(sortValue.slug, sortValue.direction)}
    >
      {sortValue.name}
    </Menu.Item>
  ));

  const currentSort = useMemo(() => sortList.find(sort => sort.slug === sortKey), [sortKey]);
  return (
    <Wrapper>
      <Sort>
        <Title>Sort by:</Title>
        <DropdownWrapper
          menu={menu}
        >
          {currentSort.name} &nbsp;
          <Icon icon="faChevronDown" size={14} />
        </DropdownWrapper>
      </Sort>

      {!hasFilter ?
        <FilterButton size="large" onClick={() => setFilter(1)}>Only Open</FilterButton> :
        <Chip isSelected onDelete={() => clearFilter()}>Only Open ({count})</Chip>
      }
    </Wrapper>
  );
};

const Wrapper = styled('nav')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1;
`;

const Sort = styled('div')`
  display: flex;
  margin-right: 14px;  
`;

const Title = styled('span')`
  color: ${({ theme }) => theme.color.grey};
  margin-right: 8px;
  white-space: nowrap;
`;

const DropdownWrapper = styled(Dropdown)`
  color: ${({ theme }) => theme.color.brand}
`;

const FilterButton = styled(Button)`
  font-size: 16px;
  color: #808F9B;
  border: 1px solid #EEF0F1;
  background: #FFFFFF;
  padding: 0 16px;
  height: 40px;
`;