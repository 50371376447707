import Swal from 'sweetalert2';

const createSwal = type => (text, options = {}) => {
  Swal.fire({
    type,
    text,
    position: 'top-end',
    timer: 2700,
    showConfirmButton: false,
    ...options,
  });
};

const success = createSwal('success');
const error = createSwal('error');
const fire = options => Swal.fire({ ...options });

export default {
  success,
  error,
  fire,
};
