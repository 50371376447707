import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import localForage from 'localforage'
import isEmpty from 'lodash/isEmpty';
import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ReactGA from 'react-ga';
import { YMInitializer } from 'react-yandex-metrika';

import { tokens } from './themes';
import store from './store';
import { AuthModalProvider } from '@common/utils/authModalContext';
import { getComponent, router } from 'router';
import { globalStyles } from './globalStyles';
import { authActions } from './app/auth';
import { redirect404 } from './@common/utils/redirect';
import { useDispatchHook } from './@common/hooks';
import { settingsActions } from './app/settings/@duck/settings';

const appStage = process.env.REACT_APP_APP_STAGE;
if(appStage === 'production') {
  Bugsnag.start({
    releaseStage: appStage,
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    enabledReleaseStages: [ 'production'],
    plugins: [new BugsnagPluginReact(React)]
  });

  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const App = () => {
  const configInit = useDispatchHook(settingsActions.init);

  const route = useSelector(state => state.router.route);
  const routes = getComponent(route);
  const projectToken = route.params.project;

  useEffect(() => {
    if(projectToken) {
      configInit(route.params.project)
        .catch(() => redirect404());
    }
  }, [configInit, projectToken, route.params.project]);

  return (
    <ThemeProvider theme={tokens}>
      <Global styles={globalStyles} />
      {routes}
    </ThemeProvider>
  );
};

const ErrorBoundary = appStage === 'production' ? Bugsnag.getPlugin('react') : React.Fragment;

async function render() {
  try {
    const user = await localForage.getItem('user');
    const project = await localForage.getItem('project');
    const showCookies = await localForage.getItem('cookieConsentDismissed');
    await store.dispatch(settingsActions.dismissCookies(showCookies));

    if(!isEmpty(user) || !isEmpty(project)) {
      store.dispatch(authActions.setConfig({ user, project }));
    }
  }
  catch(e) {
    console.warn(e);
  }

  router.start(() => {
    router.setDependency('store', store);
    ReactDOM.render(
      <ErrorBoundary>
        <Provider store={store}>
          <AuthModalProvider>
            {appStage === 'production' &&
              <YMInitializer accounts={[64585270]} options={{
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true
              }}/>
            }
            <App />
          </AuthModalProvider>
        </Provider>
      </ErrorBoundary>,
      document.getElementById('root')
    );
  });
}
window.addEventListener('DOMContentLoaded', render);
