import React from 'react';
import styled from '@emotion/styled';

import { mq } from 'themes';
import heroBg from 'assets/img/hero-bg.svg';

export const Hero = ({ title, description, background }) => (
  <Wrapper background={background}>
    <Content>
      <HeaderTitle>{title}</HeaderTitle>
      <Subtitle>{description}</Subtitle>
    </Content>
  </Wrapper>
);

const Wrapper = styled('header')`
  background: #f1f4f6 url(${heroBg}) center center;
  ${({ background }) => background && `background-image: url(${background})`};
  background-size: cover;
  text-align: center;
  color: white;
  margin-bottom: 24px;
  ${mq({
    padding: ['16px', '82px 20px'],
  })}
`;

const Content = styled('div')`
  ${mq({
    width: ['100%', null, '1170px'],
  })};
  margin: 0 auto;
`;

const HeaderTitle = styled('h1')`
  font-weight: 500;
  margin-bottom: 16px;
  ${mq({
    fontSize: ['24px', '40px'],
  })}
`;

const Subtitle = styled('p')`
  margin: 0;
  ${mq({
    fontSize: ['16px', '18px'],
  })}
`;

