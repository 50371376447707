import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import noop from 'lodash/noop';

import { router } from 'router';
import { useRoute } from '../hooks';
import { settingsSelectors } from 'app/settings/@duck/settings';

export const Link = ({ className, url, to, target, params, options, as, disabled, children, color }) => {

  const { route, navigateTo, addPrefix } = useRoute();
  const projectToken = useSelector(settingsSelectors.token);

  const handleClick = useCallback(
    e => {
      e.preventDefault();
      if (disabled) {
        return;
      }
      navigateTo(to, { ...route.params, ...params }, options);
    }, [navigateTo, to, params, route.params, options, disabled]
  );

  const project = projectToken || route.params.project;
  if (project) {
    params.project = project;
  }
  const fullPath = addPrefix(to);
  const href = url || router.buildUrl(fullPath, { ...route.params, ...params });

  return (
    <StyledLink
      as={as}
      href={href}
      className={className}
      onClick={!url ? handleClick: noop}
      color={color}
      target={target}
    >
      {children}
    </StyledLink>
  );
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  params: PropTypes.object, // eslint-disable-line
  options: PropTypes.object, // eslint-disable-line
  className: PropTypes.string,
  navigateTo: PropTypes.func,
  disabled: PropTypes.bool,
};

Link.defaultProps = {
  to: '',
  params: {},
  options: {},
  className: '',
  navigateTo: null,
  disabled: false,
};

const StyledLink = styled('a')`
  text-decoration: none;
  color: ${({ theme, color }) => color ? theme.color[color] : theme.color.brand};
  cursor: pointer;
`;
