import axios from 'axios';
import Swal from 'sweetalert2';
import get from 'lodash/get';

import { authSelectors } from 'app/auth';
import store from 'store';
import { settingsSelectors } from './app/settings/@duck/settings';

const domain = process.env.REACT_APP_API_ROOT;

const api = axios.create({
  baseURL: domain,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async config => {
  const state = store.getState();
  const authToken = authSelectors.getToken(state);
  const userId = authSelectors.userId(state);
  const projectToken = settingsSelectors.token(state);

  config.headers.common.Authorization = authToken
    ? `Bearer ${authToken}`
    : 'Basic'; //eslint-disable-line
  config.headers.common.userID = userId;
  config.headers.common.Project = projectToken;
  return config;
});

api.interceptors.response.use(
  response => {
    return Promise.resolve(response.data.data || response.data || response);
  },
  error => {
    // if (error.response.status === 403) {
    // window.location.replace(router.buildPath('app.home'));
    // }
    if (error.response && error.response.data) {
      Swal.fire({
        position: 'top-end',
        type: 'error',
        text: error.response.data.message,
        timer: 3000,
        showConfirmButton: false,
      });
    }
    return Promise.reject(get(error, 'response.data'));
  }
);

export default api;
