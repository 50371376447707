import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { Icon as _Icon, Menu, useDispatchHook } from '@common';
import { Dropdown } from '@common/formControls/Dropdown';
import { Button } from '@common/buttons/Button';
import { featuresActions, featuresSelectors } from 'app/features';

export const Status = ({ className, status, featureUUID, isAdmin }) => {
  let StyledButton;
  let icon;

  const featureStatuses = useSelector(featuresSelectors.featureStatuses);
  const changeFeatureStatus = useDispatchHook(featuresActions.changeFeatureStatus);

  const handleChangeStatus = statusId => changeFeatureStatus(featureUUID, statusId);

  switch (status.slug) {
    case 'open':
      if (!isAdmin) return null;
      StyledButton = StatusDefault;
      break;
    case 'underReview':
      icon = 'faEye';
      StyledButton = UnderReview;
      break;
    case 'planned':
      icon = 'faCalendar';
      StyledButton = Planned;
      break;
    case 'inProgress':
      icon = 'faHammer';
      StyledButton = InProgress;
      break;
    case 'complete':
      StyledButton = StatusComplete;
      icon = 'faCheck';
      break;
    case 'rejected':
      icon = 'faBan';
      StyledButton = StatusRejected;
      break;
    case 'goodIdea':
      icon = 'faThumbsUp';
      StyledButton = StatusGoodIdea;
      break;
    case 'closed':
      icon = 'faBan';
      StyledButton = Closed;
      break;
    case 'duplicate':
      icon = 'faCodeBranch';
      StyledButton = StatusGoodIdea;
      break;
    default:
      StyledButton = StatusDefault;
      break;
  }

  const menu = featureStatuses.map(status => (
    <Menu.Item key={status.slug} onClick={() => handleChangeStatus(status.id)}>{status.name}</Menu.Item>
  ));

  return (
    <Dropdown
      className={className}
      isDisabled={!isAdmin}
      menu={menu}
    >
      <StyledButton>
        {icon && <Icon icon={icon} />}
        {status.name}
      </StyledButton>
    </Dropdown>
  );
};

const StatusDefault = styled(Button)`
  color: ${({ theme }) => theme.color.lightGrey};
  padding: 6px 12px;
  background-color: #F8F9FA;
`;

const UnderReview = styled(Button)`
  color: white;
  padding: 6px 12px;
  background-color: #7B8BE5;
`;

const Planned = styled(Button)`
  color: white;
  padding: 6px 12px;
  background-color: #ECA9C1;
`;

const InProgress = styled(Button)`
  color: white;
  padding: 6px 12px;
  background-color: #88DD8B;
`;

const StatusGoodIdea = styled(Button)`
  color: white;
  padding: 6px 12px;
  background-color: #0fbcf9;
`;

const StatusRejected = styled(Button)`
  color: white;
  padding: 6px 12px;
  background-color: #DD8B88;
`;

const Closed = styled(Button)`
  color: white;
  padding: 6px 12px;
  background-color: #7770A2;
`;

const StatusComplete = styled(Button)`
  color: white;
  padding: 6px 12px;
  background-color: #05c46b;
`;

const Icon = styled(_Icon)`
  margin-right: 8px;
`;
