import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';

import { mq } from 'themes';
import { swal } from '@common';
import { useDispatchHook, useRoute } from '@common/hooks';
import { Button } from '@common/buttons/Button';
import { Field } from '@common/formControls/Field';
import { createSlug, checkForSlugErrors } from '@common/utils/createSlug';
import { authActions, authSelectors } from '../auth';
import { Title } from './components/Title';

export const CreateCompanyContainer = () => {
  const { navigateTo } = useRoute();
  const [slug, setSlug] = useState(null);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const user = useSelector(authSelectors.user);
  const createAccount = useDispatchHook(authActions.createAccount);

  useEffect(() => {
    !isLoggedIn && navigateTo('auth.register');
  }, [isLoggedIn, navigateTo]);

  const validate = useCallback((values) => {
    const errors = {};
    if(values.companyName) {
      setSlug(createSlug(values.companyName));
      const errorName = checkForSlugErrors(values.companyName);
      if(errorName) {
        errors.companyName = errorName;
      }
    }
    return errors;
  }, [setSlug])
  const handleSubmit = useCallback(async (values) => {
    const { value: { project: { token }}} = await createAccount(values);
    swal.success('Successfully created');
    await navigateTo('app.settings', { project: token});
  }, [navigateTo, createAccount]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{'Create a company | The Beyond Feedback'}</title>
      </Helmet>
      <Title>Create a company</Title>
      <Formik
        initialValues={{
          userId: user.id,
          companyName: '',
          url: '',
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FieldWrapper
              label="Company name"
              name="companyName"
              value={values.companyName}
              onChange={handleChange}
              error={errors.companyName}
              required
              innerShadow
              info={
                values.companyName.length > 4 && <Workspace>Your workspace: <URL>https://thebeyond.io/{slug}</URL></Workspace>
              }
            />
            <FieldWrapper
              label="Company Website"
              name="url"
              type="url"
              value={values.url}
              onChange={handleChange}
              innerShadow
            />
            <Button type="submit" view="primary" size="large">Launch a Company</Button>
          </form>
        )}
      </Formik>
    </React.Fragment>);
}

const FieldWrapper = styled(Field)`
  font-size: 16px;
  ${mq({
  marginBottom: ['12px', null, '26px'],
})}
`;

const Workspace = styled('span')`
  color: ${({ theme }) => theme.color.lightGrey};
`;

const URL = styled('span')`
  color: ${({ theme }) => theme.color.grey};
  font-weight: bold;
`;