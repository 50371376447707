import { storage } from '@common';

function saveToken(token) {
  storage.setItem('access-token', token);
}

function getToken() {
  return storage.getItem('access-token');
}

function removeToken() {
  storage.removeItem('access-token');
}

export const authUtils = { saveToken, getToken, removeToken };
