import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { router5Middleware } from 'redux-router5';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { router } from 'router';
import rootReducer from './root-reducer';

const middleware = applyMiddleware(promise(), thunk, router5Middleware(router));

const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(middleware)
);

export default store;
