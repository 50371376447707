import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';

import { mq } from 'themes';
import { Hero } from '@common/hero/Hero';
import { Container } from '@common/grid/Container';
import { Button } from '@common/buttons/Button';
import { useRoute } from '@common/hooks';
import { settingsSelectors } from './@duck/settings';
import { authSelectors } from '../auth';
import { InterfaceSection } from './InterfaceSection';
import { IntegrationSection } from './IntegrationSection';
import { PrivacySection } from './PrivacySection';

export const SettingsContainer = () => {
  const { title, description, background } = useSelector(settingsSelectors.project);
  const firstPageSlug = useSelector(settingsSelectors.firstPageSlug);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const [activePage, setActivePage] = useState(1);
  const { navigateTo } = useRoute();

  useEffect(() => {
    if (!isAdmin && firstPageSlug) navigateTo('features.list', { page: firstPageSlug });
  }, [isAdmin,firstPageSlug, navigateTo]);

  if (!isAdmin) return null;
  return (
    <>
      <Hero title={title} description={description} background={background}/>
      <Wrapper>
        <Sidebar>
          <SidebarNav>
            <Button block onClick={() => setActivePage(1)} view={activePage === 1 && 'light'}>Interface</Button>
            <Button block onClick={() => setActivePage(3)} view={activePage === 3 && 'light'}>Privacy</Button>
            <Button block onClick={() => setActivePage(2)} view={activePage === 2 && 'light'}>Integration</Button>
          </SidebarNav>
        </Sidebar>
        {
          activePage === 1 && <InterfaceSection />
        }
        {
          activePage === 2 && <IntegrationSection/>
        }
        {
          activePage === 3 && <PrivacySection />
        }
      </Wrapper>
    </>
  )
};

const Wrapper = styled(Container)`
  margin: 46px 0;
  ${mq({
    flexDirection: ['column', null, 'row'],
  })};
`;

const Sidebar = styled('aside')`
  ${mq({
    flex: ['1', null, '0 0 278px'],
    marginRight: [null, null, '118px'],
    marginBottom: ['20px', null, 0],
  })};
`;

const SidebarNav = styled('nav')`
  display: flex;
  flex-direction: column;
`