import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';

import { Link } from '@common';
import { useDispatchHook, useRoute } from '@common/hooks';
import { Button } from '@common/buttons/Button';
import { authActions, authSelectors } from '../auth';
import Field from './components/Field';
import { SubTitle, Title } from './components/Title';

export const ResetPasswordContainer = () => {
  const { navigateTo, route } = useRoute();
  const token = route.params.token;
  const isFulfilled = useSelector(authSelectors.isFulfilled);
  const reset = useDispatchHook(authActions.reset);

  useEffect(() => {
    isFulfilled && navigateTo('auth.login');
  }, [isFulfilled, navigateTo]);

  const handleSubmit = useCallback(values => reset({ ...values, token }), [reset, token]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{'Reset password | The Beyond Feedback'}</title>
      </Helmet>
      <Title>Reset password</Title>
      <SubTitle>Already have an account? <Link to="auth.login">Log in</Link></SubTitle>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: ''
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, errors}) => (
          <form onSubmit={handleSubmit}>
            <Field
              label="Email"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              required
            />

            <Field
              label="New Password"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              required
            />

            <Field
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={values.confirmPassword}
              onChange={handleChange}
              required
            />
            <Button type="submit" view="primary" size="large">Reset</Button>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}