import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '../icons/Icon';

export const IconButton = ({ className, icon, iconSize, onClick }) => (
  <StyledButton className={className} onClick={onClick}>
    <Icon icon={icon} size={iconSize} />
  </StyledButton>
);

IconButton.defaultProps = {
  iconSize: 20,
};

const StyledButton = styled('button')`
  border: 0;
  background: transparent;
  color: ${({ theme }) => theme.color.lightGrey};
`;
