import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { useDispatchHook } from '@common/hooks';
import { Button } from '@common/buttons/Button';
import { authActions, authSelectors } from '../auth';
import Field from './components/Field';
import { SubTitle, Title } from './components/Title';
import { Link } from '@common';

export const ForgotPasswordContainer = () => {
  const isFulfilled = useSelector(authSelectors.isFulfilled);
  const forgot = useDispatchHook(authActions.forgot);
  const handleSubmit = useCallback(values => forgot(values), [forgot]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{'Forgot password | The Beyond Feedback'}</title>
      </Helmet>
      <Title>Forgot password</Title>
      <SubTitle>Already have an account? <Link to="auth.login">Log in</Link></SubTitle>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <Field
              label="Email"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              required
              autoComplete="off"
              innerShadow
            />
            {isFulfilled && <Alert type="success">Check your email to reset a password</Alert>}
            <Button type="submit" view="primary" size="large">Reset</Button>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

const Alert = styled('div')`
  padding: 12px 16px;
  background: ${({ theme }) => theme.color.success};
  color: white;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  margin-bottom: 24px;
`;