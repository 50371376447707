import { css } from '@emotion/core';
import facepaint from 'facepaint';

const breakpoints = [768, 992, 1200];

export const tokens = {
  beyond: {
    brand: '#DD8B88',
    black: '#323950',
  },
  color: {
    brand: '#DD8B88',
    black: '#323950',
    grey: '#5B6274',
    lightGrey: '#ACB6BD',
    success: '#88DD8B',
  },
  borderRadius: {
    normal: '6px',
  }
};

export const mq = facepaint(
  breakpoints.map(bp => `@media (min-width: ${bp}px)`)
);

export const buttonOverride = css`
  border: 0;
  background: transparent;
`;
