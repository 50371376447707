import React from 'react';
import styled from '@emotion/styled/macro';

export const createSVG = (Path, width, height) => ({ className, fill }) => (
  <SVG
    className={className}
    width={width}
    height={height || width}
    viewBox={`0 0 ${width} ${height || width}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path fill={fill} />
  </SVG>
);

const SVG = styled('svg')`
  width: 1em;
  height: 1em;
`;