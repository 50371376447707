import React from 'react';
import styled from '@emotion/styled/macro';

import { mq } from 'themes';
import { Button } from '../buttons/Button';

export const Field = ({
  className,
  as,
  label,
  name,
  type,
  onChange,
  value,
  fileName,
  innerShadow,
  error,
  info,
  required,
  children,
  ...rest
}) => (
  <FormField className={className}>
    { label && <Label htmlFor={`label-${name}`}>{label} {required && <Required>*</Required>}</Label>}
    {
      type === 'select' ?
        <Select id={`label-${name}`} name={name} onChange={onChange} value={value} required={required}>
          { children }
        </Select> :
        <>
          <Input id={`label-${name}`} as={as} type={type} name={name} onChange={onChange} value={value} innerShadow={innerShadow} required={required} {...rest} />
          { type === 'file' && (
            <FileWrapper>
              <Button htmlFor={`label-${name}`} view="secondary" as="label">
                Browse
              </Button>
              {fileName && <FileName>{fileName}</FileName>}
            </FileWrapper>
          )}
        </>
    }
    { error && <Error>{error}</Error>}
    { info && <Info>{info}</Info>}
  </FormField>
);

const FormField = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Label = styled('label')`
  font-weight: 500;
  font-size: 14px;
  ${mq({
    marginBottom: ['6px', null, '8px'],
  })}
`;

const Required = styled('span')`
  padding-left: 2px;
  color: ${({ theme }) => theme.color.brand};
`

const Input = styled('input')`
  padding: 10px 14px;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  border: 1px solid #EEF0F1;
  font-size: 14px;
  box-sizing: border-box;
  ${({ innerShadow }) => innerShadow && `background: #F8F9FA;`};
  
  &[type="file"] {
    display: none;
  }
  &[disabled] {
    cursor: not-allowed;
    color: rgba(0, 0, 0, .25);
  }
  
  ${({ as }) => as === 'textarea' && `
    resize: none;
    min-height: 80px;
    font-family: inherit;
  `};
`;

const Select = styled('select')`
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #EEF0F1;
  background: white;
  font-size: 14px;
  line-height: 18px;
  appearance: none;
`

const FileWrapper = styled('div')`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.color.grey};
  font-size: 14px;
`;

const FileName = styled('span')`
  margin-left: 14px;
`

const Error = styled('p')`
  margin: 8px 0 0;
  color: #DD8B88;
  font-size: 14px;
`
const Info = styled('p')`
  margin: 8px 0 0;
  font-size: 14px;
`;