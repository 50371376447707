import React from 'react';
import { Block, Field, Heading } from './components';
import { Button } from '../../@common/buttons/Button';
import styled from '@emotion/styled/macro';
import { useFormik } from 'formik';

import swal from '@common/swal/swal';
import { useDispatchHook } from '@common/hooks';
import { settingsActions, settingsSelectors } from './@duck/settings';
import { useSelector } from 'react-redux';

export const PrivacySection = () => {
  const { isPrivate, privatePosts, privateForm } = useSelector(settingsSelectors.project);
  const updateProject = useDispatchHook(settingsActions.updateProject);

  const formik = useFormik({
    initialValues: {
      isPrivate,
      privatePosts,
      privateForm,
    },
    onSubmit: ({ isPrivate, privatePosts, privateForm }) => {
      updateProject({ isPrivate, privatePosts, privateForm })
        .then(() => swal.success('Successfully updated', { timer: 1500 }))
        .catch(() => swal.error('Something went wrong'))
    },
    enableReinitialize: true,

  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Heading level={1}>Privacy</Heading>
      <Block
        title="Make project private"
        description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, saepe."
      >
        <Field
          type="checkbox"
          name="isPrivate"
          checked={formik.values.isPrivate}
          onChange={formik.handleChange}
        />
      </Block>
      <Block
        title="Make posts private"
        description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, saepe."
      >
        <Field
          type="checkbox"
          name="privatePosts"
          checked={formik.values.privatePosts}
          onChange={formik.handleChange}
        />
      </Block>
      <Block
        title="Hide post form"
        description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, saepe."
      >
        <Field
          type="checkbox"
          name="privateForm"
          checked={formik.values.privateForm}
          onChange={formik.handleChange}
        />
      </Block>
      <Button type="submit" view="primary" size="large">Save</Button>
    </Form>
  );
}

const Form = styled('form')`
  display: flex;
  flex-direction: column;
`;