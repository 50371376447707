import React from 'react';
import styled from '@emotion/styled/macro';

import { mq } from 'themes';
import { Field } from '@common/formControls/Field';

export default ({ fullWidth, children, ...rest }) => <FieldWrapper fullWidth={fullWidth} {...rest}>{children}</FieldWrapper>


const styles = ({ fullWidth }) => mq({
  marginRight: [null, null, '20px'],
  width: fullWidth ? '100%' : ['100%', null, '280px'],
  marginBottom: ['16px', null, 'unset'],
});
const FieldWrapper = styled(Field)`
  ${styles};
`;