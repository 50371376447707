import React from 'react';
import styled from '@emotion/styled';

import { mq } from 'themes';
import { LogoBeyond } from '@common/LogoBeyond';
import registerIntro from 'assets/landing/register-intro.svg';
import registerBg from 'assets/landing/register-bg.svg';

export const AuthContainer = ({ children }) => {
  return (
    <Wrapper>
      <Content>
        <Header>
          <LogoBeyond />
          <Picture>
            <source srcSet={registerIntro} type="image/svg+xml" />
            <img src={registerIntro} alt="Nice to meet ya"/>
          </Picture>
        </Header>
        { children }
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled('div')`
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial sans-serif;
  background: url(${registerBg}) no-repeat bottom;
  background-size: contain;
  ${mq({
    height: [null, '100%'],
    marginBottom: ['20px', 0],
    padding: ['0 16px 40px', '0 16px'],
  })};
`;

const Content = styled('div')`
  ${mq({
    width: ['100%', null, '400px'],
  })};
  margin: 0 auto;
`;

const Header = styled('header')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Picture = styled('picture')`
  display: flex;
  width: 220px;
`;
