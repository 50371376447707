import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import includes from 'lodash/includes';
import get from 'lodash/get';

import { settingsSelectors } from 'app/settings/@duck/settings';

export const useRoute = () => {
  const route = useSelector(state => state.router.route);
  const previousRoute = useSelector(state => state.router.previousRoute);
  const projectToken = useSelector(settingsSelectors.token);
  const dispatch = useDispatch();
  const isFullScreen = useMemo(
    () => route && route.name && !!route.name.includes('fullscreen'),
    [route]
  );
  const page = useMemo(() => get(route, 'params.page'), [route]);

  const addPrefix = path => {
    const prefix = route.name.split('.')[0];
    if (
      includes(['app', 'fullscreen'], prefix) &&
      !includes(['404', 'landing', 'register'], path)
    ) {
      return `${prefix}.${path}`;
    }
    return path;
  };

  const navigateTo = (path, newParams = {}) => {
    const fullPath = addPrefix(path);
    return dispatch(
      routerActions.navigateTo(fullPath, {
        ...newParams,
        project: newParams.project || projectToken,
      })
    );
  };

  return { navigateTo, route, page, previousRoute, isFullScreen, addPrefix };
};
