import { combineActions, handleActions } from 'redux-actions';
import { Map, fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import localForage from 'localforage';

import api from 'api';
import { swal } from '@common';

const initialState = Map({
  user: {},
  hasAccount: undefined,
  isFulfilled: false,
});

export const reducer = handleActions(
  {
    AUTH_SET_CONFIG: (state, { payload }) =>
      state.set('user', payload.user ? fromJS(payload.user) : Map()),

    [combineActions(
      'AUTH_LOGIN_FULFILLED',
      'AUTH_REGISTER_FULFILLED',
      'AUTH_FETCH_USER_DATA_FULFILLED',
      'AUTH_STORE_TOKEN_FULFILLED'
    )]: (state, { payload }) => state.set('user', fromJS(payload)),
    AUTH_CREATE_ACCOUNT_FULFILLED: (state, { payload }) =>
      state.set('user', fromJS(payload.user)),
    AUTH_CHECK_EMAIL_FULFILLED: (state, { payload }) =>
      state.set('hasAccount', payload.has_account),
    AUTH_CHECK_EMAIL_CLEAR: state => state.set('hasAccount', initialState.get('hasAccount')),
    AUTH_LOGOUT: state => state.set('user', Map()),
    AUTH_FORGOT_PASSWORD_PENDING: state => state.set('isFulfilled', false),
    AUTH_FORGOT_PASSWORD_FULFILLED: state => state.set('isFulfilled', true),
    AUTH_RESET_PASSWORD_PENDING: state => state.set('isFulfilled', false),
    AUTH_RESET_PASSWORD_FULFILLED: state => state.set('isFulfilled', true),
  },
  initialState
);

// Actions

export const authActions = {
  setConfig: createAction('AUTH_SET_CONFIG'),
  login: createAction('AUTH_LOGIN', async ({ email, password }) => {
    const payload = await api.post('login', { email, password });
    await localForage.setItem('user', payload);
    return payload;
  }),
  register: createAction('AUTH_REGISTER', async values => {
    const payload = await api.post('register', values);
    await localForage.setItem('user', payload);
    return payload;
  }),
  checkEmail: createAction('AUTH_CHECK_EMAIL', email =>
    api.post('checkEmail', { email })
  ),
  clearEmailChecked: createAction('AUTH_CHECK_EMAIL_CLEAR'),
  createAccount: createAction(
    'AUTH_CREATE_ACCOUNT',
    async ({ companyName, url, userId }) => {
      const payload = await api.post('createAccount', {
        tenant: companyName,
        user_id: userId,
        url,
      });
      await localForage.setItem('user', payload.user);
      await localForage.setItem('project', payload.project);
      return payload;
    }
  ),
  forgot: createAction('AUTH_FORGOT_PASSWORD', ({ email }) =>
    api.post('forgot', { email })
  ),
  reset: createAction(
    'AUTH_RESET_PASSWORD',
    ({ email, password, confirmPassword, token }) =>
      api.post('reset', {
        email,
        password,
        password_confirmation: confirmPassword,
        token,
      })
  ),
  logout: createAction('AUTH_LOGOUT'),
  setUser: createAction('AUTH_SET_USER'),
  fetchUserByToken: createAction('AUTH_FETCH_USER_DATA', () =>
    api.get('checkToken')
  ),
  storeToken: createAction('AUTH_STORE_TOKEN', token =>
    api.post('users/token', { token })
  ),
  checkToken: createAction('AUTH_CHECK_TOKEN', token =>
    api.post('users/', token)
  ),
};

export const authThunks = {
  logout: () => async dispatch => {
    try {
      await localForage.removeItem('user');
      await dispatch(authActions.logout());
    } catch (e) {
      swal.error(e.message);
    }
  },
};
