import React from 'react';
import styled from '@emotion/styled';

import { CloseIcon } from '@common/icons/svg/Close';

export const Chip = ({ onDelete, isSelected, children}) => {
  return (
    <Container isSelected={isSelected} onClick={onDelete}>
      <span>{children}</span>
      { onDelete && <CloseIconWrapper />}
    </Container>
  );
}

const Container = styled('button')`
  background: transparent;
  padding: 0 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  line-height: 1;
  height: 40px;
  ${({ theme }) => `border: 2px solid ${theme.color.lightGrey}`};

  ${({ theme, isSelected }) => isSelected && `
    border-color: ${theme.color.brand};
  `};
`;

const CloseIconWrapper = styled(CloseIcon)`
  margin-left: 8px;
`