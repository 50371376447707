import React from 'react';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';

import { mq } from 'themes';
import { CopyToClipboard } from '@common';
import { Button } from '@common/buttons/Button';
import { settingsSelectors } from './@duck/settings';
import { Field, Heading, Block } from './components';

export const IntegrationSection = () => {
  const projectToken = useSelector(settingsSelectors.token);

  const domain = process.env.REACT_APP_URL;
  const projectURL = `${domain}/${projectToken}`;
  const fullscreenURL = `${domain}/fullscreen/${projectToken}`;
  const embedCode = `<iframe src="${fullscreenURL}" width="100%" height="100%"></iframe>`;

  return (
    <Content>
      <Heading level={1}>Copy link or embed to your site</Heading>
      <Block
        title="Link on your company feedback"
        description="Copy the link below and paste it to your website. The link will redirect your customers to your feedback page."
      >
        <FieldWithButton>
          <Field value={projectURL} readOnly innerShadow/>
          <CopyToClipboard text={projectURL} as={Button} view="secondary" icon="copy">Copy</CopyToClipboard>
        </FieldWithButton>
      </Block>

      <Block
        title="Fullscreen URL"
        description="To embed only feedback section without navigations, bars and styles to your site copy the link below."
      >
        <FieldWithButton>
          <Field value={fullscreenURL} readOnly innerShadow/>
          <CopyToClipboard text={fullscreenURL} as={Button} view="secondary"  icon="copy">Copy</CopyToClipboard>
        </FieldWithButton>
      </Block>

      <Block
        title="Embed code"
        description="Integrate The Beyond feedback directly on your website page"
      >
        <FieldWithButton>
          <Field value={embedCode} readOnly innerShadow as="textarea" rows={5}/>
          <CopyToClipboard text={embedCode} as={Button} view="secondary" icon="copy">Copy</CopyToClipboard>
        </FieldWithButton>
      </Block>
    </Content>
  );
}

const Content = styled('section')`
  display: flex;
  flex-direction: column;
`;

const FieldWithButton = styled('div')`
  display: flex;
  margin-bottom: 16px;
  ${mq({
  alignItems: ['flex-start', null, 'flex-end'],
})};
`;
