import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

import { Icon } from '..';

const Menu = forwardRef(({ className, children }, ref) => (
  <List ref={ref} className={className}>{children}</List>
));

const MenuItem = ({ className, onClick, icon, children }) => (
  <ListItem className={className} onClick={onClick}>
    {icon && <MenuIcon icon={icon} />}
    {children}
  </ListItem>
);

const List = styled('ul')`
  background: white;
  z-index: 1;
  box-shadow: 0 2px 16px rgba(33, 43, 54, 0.08), 0 0 0 rgba(6, 44, 82, 0.1);
  border-radius: 4px;
`;

const ListItem = styled('li')`
  padding: 12px 20px;
  cursor: pointer;

  &:hover {
    background: #f5f7f8;
  }
`;

const MenuIcon = styled(Icon)`
  margin-right: 12px;
`;

Menu.Item = MenuItem;
export default Menu;