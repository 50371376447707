import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

import { Link } from '@common';
import { useDispatchHook, useRoute } from '@common/hooks';
import { Button } from '@common/buttons/Button';
import { authActions, authSelectors } from '../auth';
import Field from './components/Field';
import { Title, SubTitle } from './components/Title';

export const LogInContainer = () => {
  const { previousRoute, navigateTo } = useRoute();
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const lastCreatedProject = useSelector(authSelectors.lastCreatedProject);
  const login = useDispatchHook(authActions.login);
  const clearEmailChecked = useDispatchHook(authActions.clearEmailChecked);

  useEffect(() => {
    clearEmailChecked();
    if(isLoggedIn) {
      lastCreatedProject ?
        navigateTo('app', { project: lastCreatedProject}) :
        navigateTo('auth.company')
    }
  }, [isLoggedIn, navigateTo, clearEmailChecked, previousRoute, lastCreatedProject])

  const handleSubmit = useCallback(
    (values) => login(values), [login]
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>{'Log In | The Beyond Feedback'}</title>
      </Helmet>
      <Title>Log in</Title>
      <SubTitle>Don't have an account? <Link to="auth.register">Sign Up</Link></SubTitle>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <Field
              label="Email"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              required
              autoComplete="off"
              innerShadow
            />

            <Field
              label="Password"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              required
              innerShadow
            />
            <Footer>
              <Link to="auth.forgot">Don't remember your password?</Link>
              <Button type="submit" view="primary" size="large">Log In</Button>
            </Footer>
          </form>
        )}
      </Formik>
    </React.Fragment>
  )};

const Footer = styled('footer')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`