import React, { useCallback, useContext } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { mq } from 'themes';
import get from 'lodash/get';

import { SharedForm } from '@common';
import { Field } from '@common/formControls/Field';
import { useDispatchHook, useRoute } from '@common/hooks';
import { AuthModalContext } from '@common/utils/authModalContext';
import { authSelectors } from 'app/auth';
import { featuresActions } from 'app/features';
import { settingsSelectors } from 'app/settings/@duck/settings';

export const FeatureForm = () => {
  const { navigateTo } = useRoute();
  const { login: [, loginOpen] } = useContext(AuthModalContext);
  const userId = useSelector(authSelectors.userId);
  const currentPage = useSelector(settingsSelectors.currentPage);
  const currentPageUUID = useSelector(settingsSelectors.currentPageUUID);
  const saveFeature = useDispatchHook(featuresActions.saveFeature);

  const handleSubmit = useCallback((values, { resetForm }) => {
    if(userId) {
      saveFeature(currentPageUUID, values).then(
        ({ value }) =>
          navigateTo('features.one', { featureId: get(value, 'uuid'), page: 'features'})
      )
      resetForm();
    }
    else {
      loginOpen(true);
    }
  }, [userId, currentPageUUID, loginOpen, navigateTo, saveFeature]);
  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form title={get(currentPage, 'form_title', '')} cta="Submit" onSubmit={handleSubmit}>
          <FieldWrapper
            label="Suggestion"
            name="name"
            placeholder="Your suggestion"
            required
            autoComplete="off"
            onChange={handleChange}
            value={values.name}
          />

          <FieldWrapper
            label="Description"
            as="textarea"
            name="description"
            onChange={handleChange}
            value={values.description}
          />
        </Form>
      )}
    </Formik>
  );
};

const Form = styled(SharedForm)`
  ${mq({
    marginBottom: ['16px', null, '28px'],
  })}
`;

const FieldWrapper = styled(Field)`
  margin-bottom: 26px;
`;
