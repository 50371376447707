import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import localForage from 'localforage';
import styled from '@emotion/styled';

import { mq } from 'themes';
import { useDispatchHook, useRoute } from '@common/hooks';
import { settingsSelectors } from 'app/settings/@duck/settings';
import { authActions } from './index';

export const TokenContainer = () => {
  const { route, navigateTo } = useRoute();

  const storeToken = useDispatchHook(authActions.storeToken);
  const token = route.params.token;
  const projectToken = useSelector(settingsSelectors.token);
  const firstPageSlug = useSelector(settingsSelectors.firstPageSlug);

  const handleSetToken = useCallback( async () => {
    await storeToken(token).then(async response => {
      const user = response.value;
      await localForage.setItem('token', token);
      await localForage.setItem('user', user);
    });
  }, [storeToken, token]);

  useEffect(() => {
    if(projectToken && firstPageSlug) {
      handleSetToken().then(() => navigateTo('features.list', { project: projectToken, page: firstPageSlug }));
    }
  }, [navigateTo, handleSetToken, projectToken, firstPageSlug]);
  return (
    <Content>
      <h1>Loading...</h1>
    </Content>
  );
};

const Content = styled('div')`
  ${mq({
    width: ['100%', null, '1170px'],
  })};
  margin: 0 auto;
`;
