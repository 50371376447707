import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import { Menu } from '@common';
import { useOutsideAlerter } from '@common/clickOutside/clickOutside';

export const Dropdown = ({
  className,
  isDisabled,
  menu,
  children,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => toggleIsOpen(false));

  useEffect(() => {
    function handleCloseDropdown(event) {
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        toggleIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('click', handleCloseDropdown);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleCloseDropdown);
    };
  }, [toggleIsOpen]);

  return (
    <DropDownWrapper>
      <div className={className} onClick={() => toggleIsOpen(true)}>
        {children}
      </div>
      {!isDisabled && isOpen && (
        <DropDownList ref={wrapperRef}>
          {menu}
        </DropDownList>
      )}
    </DropDownWrapper>
  );
};

const DropDownWrapper = styled('div')`
  position: relative;
  cursor: pointer;
`;

const DropDownList = styled(Menu)`
  position: absolute;
  min-width: 280px;
`;

Dropdown.defaultProps = {
  className: null,
  isDisabled: false,
};

Dropdown.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};
