import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

export const USER_ROLES = {
  USER_DATA: 0,
  USER: 1,
  MODERATOR: 2,
  ADMIN: 10,
  SUPER_ADMIN: 100,
};

const user = createSelector(
  state => state.auth.get('user'),
  user => !isEmpty(user) && user.toJS()
);

const userId = createSelector(
  state => state.auth.getIn(['user', 'id']),
  userId => userId
);

const getToken = createSelector(
  state => state.auth.getIn(['user', 'token']),
  token => token
);

const isAdmin = createSelector(
  state => state.auth.getIn(['user', 'roles']),
  state => state.settings.get('project'),
  (userRolesImmutable, projectImmutable) =>
    projectImmutable &&
    userRolesImmutable &&
    userRolesImmutable.get(projectImmutable.get('token')) >= USER_ROLES.ADMIN
);

const rolesSelectorFactory = roleName =>
  createSelector(
    state => state.auth.getIn(['user', 'roles']),
    roles => (roles ? roles.some(role => role.get('name') === roleName) : false)
  );

const roles = {
  admin: rolesSelectorFactory('admin'),
};

const isLoggedIn = createSelector(
  state => state.auth.hasIn(['user', 'id']),
  isLoggedIn => isLoggedIn
);

const emailChecked = createSelector(
  state => state.auth.get('hasAccount'),
  hasAccount => !isUndefined(hasAccount),
);
const hasAccount = createSelector(
  state => state.auth.get('hasAccount'),
  hasAccount => !!hasAccount
);

const lastCreatedProject = createSelector(
  state => state.auth.getIn(['user', 'roles']),
  roles => roles && roles.keySeq().last(),
);

const isFulfilled = createSelector(
  state => state.auth.get('isFulfilled'),
  isFulfilled => isFulfilled,
)
export const authSelectors = {
  user,
  userId,
  getToken,
  roles,
  isLoggedIn,
  isAdmin,
  emailChecked,
  hasAccount,
  lastCreatedProject,
  isFulfilled,
};
