import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import FlipMove from 'react-flip-move';
import styled from '@emotion/styled/macro';
import isEmpty from 'lodash/isEmpty';

import { mq } from 'themes';
import { Container } from '@common/grid/Container';
import { Hero } from '@common/hero/Hero';
import { useDispatchHook, useRoute } from '@common/hooks';
import { Card } from '@common/cards/Card';
import { Link } from '@common/link/Link';
import { featuresActions, featuresSelectors } from 'app/features';
import { settingsSelectors } from 'app/settings/@duck/settings';
import { TopNav, Feature, FeatureForm, EmptyList } from './components';
import { authSelectors } from '../auth';

export const ListFeaturesContainer = () => {
  const { isFullScreen } = useRoute();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const projectToken = useSelector(settingsSelectors.token);
  const { title, description, background, privateForm, privatePosts } =  useSelector(settingsSelectors.project);
  const featuresList = useSelector(featuresSelectors.featuresList);
  const isFetched = useSelector(featuresSelectors.isFetched);
  const currentPageUUID = useSelector(settingsSelectors.currentPageUUID);
  const fetchFeatures = useDispatchHook(featuresActions.fetchFeatures);
  const hasAccessToPosts = useMemo(() => isAdmin || !privatePosts, [isAdmin, privatePosts]);
  const hasAccessToForm = useMemo(() => isAdmin || !privateForm, [isAdmin, privateForm]);

  console.log('has access', hasAccessToPosts, hasAccessToForm);
  const features = useMemo(() => featuresList.filter(({ isArchived }) => !isArchived), [featuresList]);

  useEffect(() => {
    projectToken && currentPageUUID && fetchFeatures(currentPageUUID);
  }, [projectToken, fetchFeatures, currentPageUUID, isAdmin]);

  return (
    <>
      {!isFullScreen && (
        <Hero
          title={title}
          description={description}
          background={background}
        />
      )}
      <Main as={!isFullScreen && Container}>
        {
          hasAccessToPosts && (
            <FeaturesWrapper>
              <TopNav />
              {
                isEmpty(features) && isFetched && <EmptyList />
              }
              <FlipMove typeName="ul">
                {features.map(feature => (
                  <Feature key={feature.id} uuid={feature.uuid} />
                ))}
              </FlipMove>
            </FeaturesWrapper>
          )
        }
        <Sidebar>
          <SidebarContent>
            {hasAccessToForm && <FeatureForm />}
            {hasAccessToPosts && (
              <ArchiveButton as={Link} to="features.archive">
                Archive
              </ArchiveButton>
            )}
          </SidebarContent>
        </Sidebar>
      </Main>
    </>
  );
};

const Main = styled('main')`
  position: relative;
  display: flex;
  ${mq({
    flexDirection: ['column', null, 'row'],
  })};
`;

const FeaturesWrapper = styled('div')`
  flex: 1;
  ${mq({
    order: [1, null, 0],
  })}
`;

const Sidebar = styled('aside')`
  margin-bottom: 16px;
  ${mq({
    order: [0, null, 1],
    flex: ['0 0 100%', '0 0 380px'],
    margin: ['0 auto 16px', null, '56px auto'],
    width: ['100%', null, 'auto'],
  })}
`;

const SidebarContent = styled('div')`
  display: flex;
  flex-direction: column;
  
  ${mq({
    marginLeft: [null, '18px'],
    position: [null, 'sticky'],
    top: [null, '98px'],
  })};
`;

const ArchiveButton = styled(Card)`
  padding: 20px 24px;
`