import React from 'react';
import styled from '@emotion/styled';

import { mq } from 'themes';

export const BreadCrumbs = ({ children}) => (
  <List>{children}</List>
)

const List = styled('ul')`
  list-style: none;
  display: flex;
  ${mq({
    marginBottom: ['16px', null, '34px'],
  })};
`;

export const BreadCrumb = ({ children}) => (
  <Item>{children}</Item>
)

const Item = styled('li')`
  white-space: nowrap;
  word-break: break-word;
  &::after {
    content: ' / ';
    padding-left: 14px;
    padding-right: 14px;
  }
  &:last-of-type {
    white-space: initial;
    &::after {
      content: '';
    }
  }
`;