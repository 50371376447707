import React from 'react';
import {
  EditOutlined,
  EnterOutlined,
  DeleteOutlined,
  SettingOutlined,
  CommentOutlined,
  CopyOutlined,
  LogoutOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import {
  faTrash,
  faCheckDouble,
  faCommentAlt,
  faReply,
  faArrowUp,
  faBan,
  faCheck,
  faThumbsUp,
  faEye,
  faEyeSlash,
  faCalendar,
  faHammer,
  faCodeBranch,
  faChevronDown,
  faPencilAlt,
  faPen,
} from '@fortawesome/free-solid-svg-icons';

import { FaIcon } from './FaIcon';
import * as SVGIcons from './svg';

export const Icon = ({ className, icon, fill, ...rest }) => {
  if(icon.startsWith('fa')) {
    const Icons = {
      faCommentAlt,
      faCheckDouble,
      faTrash,
      faReply,
      faArrowUp,
      faBan,
      faCheck,
      faThumbsUp,
      faEye,
      faEyeSlash,
      faCalendar,
      faHammer,
      faCodeBranch,
      faChevronDown,
      faPencilAlt,
      faPen,
    }
    return <FaIcon className={className} icon={Icons[icon]} {...rest} />
  }
  const Icons = {
    edit: EditOutlined,
    reply: EnterOutlined,
    delete: DeleteOutlined,
    gear: SettingOutlined,
    comment: CommentOutlined,
    copy: CopyOutlined,
    logout: LogoutOutlined,
    info: InfoCircleFilled,
    close: SVGIcons.CloseIcon,
    longArrow: SVGIcons.LongArrow,
  }

  const SVGIcon = Icons[icon];
  return (SVGIcon ? <SVGIcon fill={fill} className={className} /> : null);
}

Icon.defaultProps = {
  fill: 'currentColor',
}