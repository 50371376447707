import React from 'react';
import styled from '@emotion/styled';

import { mq } from 'themes';
import { Button } from '../buttons/Button';
import { Card } from '../cards/Card';

export const SharedForm = ({
  className,
  title,
  headerIcon,
  onSubmit,
  cta,
  children,
}) => (
  <Wrapper className={className}>
    <Header>
      <Title>{title}</Title>
      {headerIcon}
    </Header>
    <Form onSubmit={onSubmit}>
      {children}
      {cta && <Button type="submit" size="large" view="primary">{cta}</Button>}
    </Form>
  </Wrapper>
);

const Wrapper = styled(Card)`
  font-size: 14px;
`;

const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  position: relative;
  ${mq({
    padding: ['12px 16px', null, '24px 34px 32px'],
  })}
`;

const Title = styled('h2')`
  font-weight: 500;
  margin: 0;
  ${mq({
    fontSize: ['16px', null, '24px'],
  })}
`;

const Form = styled('form')`
  display: flex;
  flex-flow: column;
  ${mq({
    padding: ['8px 16px 16px', null, '0 32px 32px'],
  })}
`;

SharedForm.defaultProps = {
  className: '',
  headerIcon: null,
};
