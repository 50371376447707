let items = {};
class MemoryStorage {
  static setItem(key, value) {
    items[key] = value;
  }
  static getItem(key) {
    return items[key] || null;
  }
  static key(index) {
    return Object.keys(items)[index];
  }
  static get() {
    return items;
  }
  static removeItem(key) {
    delete items[key];
  }
  static clear() {
    items = {};
  }
}
Object.defineProperty(MemoryStorage.prototype, 'length', {
  get: () => Object.keys(items).length,
});

function isLocalStorageSupported() {
  try {
    localStorage.setItem('localStorage', 1);
    localStorage.removeItem('localStorage');
    return true;
  } catch (e) {
    return false;
  }
}

export const storage = isLocalStorageSupported()
  ? window.localStorage
  : new MemoryStorage();
