import React from 'react';
import styled from '@emotion/styled';

import { mq } from 'themes';
import emptyBg from 'assets/img/empty-bg.svg';
import emptyPlaceholder from 'assets/img/empty-placeholder.svg';

export const EmptyList = () => (
  <EmptyWrapper>
    <Placeholder src={emptyPlaceholder} alt="" width="50%" />
  </EmptyWrapper>
);

const EmptyWrapper = styled('div')`
  background: #fff url(${emptyBg}) no-repeat right center;
  border: 1px solid #eef0f1;
  border-radius: 6px;
  padding: 42px 68px;
  min-height: 150px;
  margin-bottom: 16px;
`;

const Placeholder = styled('img')`
  width: 50%;
  ${mq({
    display: ['none', 'block'],
  })};
`;