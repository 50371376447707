import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';

import { useDispatchHook, useRoute } from '@common/hooks';
import { featuresActions } from './index';

export const FeaturesWrapper = ({ children }) => {
  const { isFullScreen } = useRoute();
  const fetchFeatureStatuses = useDispatchHook(featuresActions.fetchFeatureStatuses);

  useEffect(() => {
    fetchFeatureStatuses();
  }, [fetchFeatureStatuses]);
  return (
    <Wrapper hasBackground={!isFullScreen}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  flex: 1;
  background: ${({ hasBackground}) => hasBackground && '#F8F9FA'};
`;

