import React, { useEffect, useContext, useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import { useFormik } from 'formik';
import get from 'lodash/get';

import { mq } from 'themes';
import {
  Link,
  Comment,
  BreadCrumbs,
  BreadCrumb,
  useRoute,
  useDispatchHook,
  swal,
  Icon,
  Menu,
} from '@common';
import { IconButton } from '@common/buttons/IconButton';
import { Container } from '@common/grid/Container';
import { AuthModalContext } from '@common/utils/authModalContext';
import { Card } from '@common/cards/Card';
import { Field } from '@common/formControls/Field';
import { Button } from '@common/buttons/Button';
import { Space } from '@common/layout/Space';
import { getTimeDistance } from '@common/utils/getTimeDistance';
import { Dropdown } from '@common/formControls/Dropdown';
import {
  featuresActions,
  featuresSelectors,
} from 'app/features';
import { authSelectors } from 'app/auth';
import { Status } from './components/Status';
import { Voting } from './components/Voting';
import { settingsSelectors } from 'app/settings/@duck/settings';

export const FeatureContainer = () => {
  const { route, navigateTo } = useRoute();
  const [editMode, setEditMode] = useState(false);
  const uuid = route.params.featureId;
  const currentPageSlug = useSelector(settingsSelectors.currentPageSlug);
  const makeFeature = useMemo(() => featuresSelectors.makeFeature(), []);
  const feature = useSelector(state => makeFeature(state, uuid));
  const userId = useSelector(authSelectors.userId);
  const isAdmin = useSelector(authSelectors.isAdmin);

  const fetchFeatureWithComments = useDispatchHook(featuresActions.fetchFeatureWithComments);
  const putComment = useDispatchHook(featuresActions.putComment);
  const toggleIsAnswered = useDispatchHook(featuresActions.toggleIsAnswered);
  const deleteComment = useDispatchHook(featuresActions.deleteComment);
  const updateFeature = useDispatchHook(featuresActions.updateFeature);
  const deleteFeature = useDispatchHook(featuresActions.deleteFeature);
  const { login: [, loginOpen] } = useContext(AuthModalContext);

  useEffect(() => {
    fetchFeatureWithComments(route.params.featureId);
  }, [route.params.featureId, fetchFeatureWithComments ]);

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if(userId) {
        await putComment(uuid, values.comment);
        window.scrollTo(0, document.body.scrollHeight);
        resetForm();
      }
      else {
        loginOpen(true);
      }
    }
  });

  const formikPost = useFormik({
    initialValues: {
      name: get(feature, 'name'),
      description: get(feature, 'description'),
      commentsTitle: get(feature, 'commentsTitle', 'Comments'),
    },
    onSubmit: (values, { resetForm }) => {
      updateFeature(uuid, {
        name: values.name,
        description: values.description,
        commentsTitle: values.commentsTitle,
      });
      resetForm();
    },
    enableReinitialize: true,
  });

  const canModeratePost = useMemo(() => get(feature, 'author.id') === userId || isAdmin, [feature, userId, isAdmin]);

  const handleDelete = () => {
    swal
      .fire({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      })
      .then(async ({ value }) => {
        if (value) {
          await deleteFeature(uuid);
          swal.success('Poof! Your feature has been deleted!');
          currentPageSlug && navigateTo('features.list', { page: currentPageSlug });
        }
      });
  };

  const handleHidePage = useCallback(() => updateFeature(
    uuid, { isHidden: 1 - get(feature, 'isHidden')}),
    [uuid, feature, updateFeature]);

  const handleHideComments = useCallback(() => updateFeature(
    uuid, { isCommentsHidden: 1 - get(feature, 'isCommentsHidden') }),
    [uuid, feature, updateFeature]);

  const handleHideVotingResults = useCallback(() => updateFeature(
    uuid, { isVotingResultsPrivate: 1 - get(feature, 'isVotingResultsPrivate')}
  ), [uuid, feature, updateFeature]);

  const handleHideCommentsForUsers = useCallback(() => updateFeature(
    uuid, { isCommentsHiddenForUsers: 1 - get(feature, 'isCommentsHiddenForUsers') }),
    [uuid, feature, updateFeature]);

  const handleHideVoting = useCallback(() => updateFeature(
    uuid, { isVotingHiddenForUsers: 1 - get(feature, 'isVotingHiddenForUsers') }),
    [uuid, feature, updateFeature]);

  const handleSaveEditedPost = useCallback(() => {
    formikPost.handleSubmit();
    setEditMode(false);
  }, [formikPost]);

  const handleCancelEdit = useCallback(() => {
    setEditMode(false);
    formikPost.resetForm();
  }, [formikPost]);

  const settingsMenu = (
    <>
      <Menu.Item onClick={handleDelete} icon="faTrash">Delete</Menu.Item>
      {isAdmin && (
        <>
          <Menu.Item onClick={handleHidePage} icon={get(feature, 'isHidden') ? 'faEye' : 'faEyeSlash'}>
            Make page {get(feature, 'isHidden') ? 'public' : 'private'}
          </Menu.Item>
          <Menu.Item onClick={handleHideComments} icon={ get(feature, 'isCommentsHidden') ? 'faEye' : 'faEyeSlash'}>
            { get(feature, 'isCommentsHidden') ? 'Enable' : 'Disable'} comments
          </Menu.Item>
          <Menu.Item onClick={handleHideCommentsForUsers} icon={get(feature, 'isCommentsHiddenForUsers') ? 'faEye' : 'faEyeSlash'}>
            {get(feature, 'isCommentsHiddenForUsers') ? 'Show' : 'Hide'} comments for users
          </Menu.Item>
          <Menu.Item onClick={handleHideVotingResults} icon={get(feature, 'isVotingResultsPrivate') ? 'faEye' : 'faEyeSlash'}>
            Make voting results {get(feature, 'isVotingResultsPrivate') ? 'public' : 'private'}
          </Menu.Item>
          <Menu.Item onClick={handleHideVoting} icon={get(feature, 'isVotingHiddenForUsers') ? 'faEye' : 'faEyeSlash'}>
            {get(feature, 'isVotingHiddenForUsers') ? 'Show' : 'Hide'} voting for users
          </Menu.Item>
        </>
      )}
    </>
  );

  if(!feature) return null;
  return (
    <Wrapper as={Container}>
      <BreadCrumbs>
        <BreadCrumb>
          <Link to="features.list">Home</Link>
        </BreadCrumb>
        <BreadCrumb>{get(feature, 'name')}</BreadCrumb>
      </BreadCrumbs>
      <Actions>
        <StatusWrapper status={get(feature, 'status')} featureUUID={uuid} isAdmin={isAdmin} />
        {(isAdmin || !get(feature, 'isVotingHiddenForUsers')) && <Voting feature={feature} />}
        {canModeratePost && (
          <SettingsBlock>
            {editMode ?
            <>
              <Button view="primary" onClick={handleSaveEditedPost}>Save</Button>
              <Button view="light" onClick={handleCancelEdit}>Cancel</Button>
            </> : <IconButton onClick={() => setEditMode(!editMode)} icon="faPencilAlt" />
            }
            <Dropdown menu={settingsMenu}>
              <SettingsButton icon="gear" />
            </Dropdown>
          </SettingsBlock>
        )}
      </Actions>
      <FeatureWrapper>
        {editMode ?
          <FieldTitle
            name="name"
            onChange={formikPost.handleChange}
            value={formikPost.values.name}
            autoFocus
          /> : <Title>{get(feature, 'name')}</Title>}
        <Meta size="large">
          {get(feature, 'author') && <Profile>{get(feature, 'author.name') || get(feature, 'author.email')}</Profile>}
          <Space>
            <Time>{getTimeDistance(new Date(get(feature, 'createdAt')))} ago</Time>
            {get(feature, 'editedAt') && <Time> (edited {getTimeDistance(new Date(get(feature, 'editedAt')))} ago)</Time>}
          </Space>
        </Meta>
        {
          get(feature, 'description') && (
            <>
              {editMode ?
                <Field
                  as="textarea"
                  name="description"
                  onChange={formikPost.handleChange}
                  value={formikPost.values.description}
                /> : <Description>{get(feature, 'description')}</Description>}
            </>
          )
        }
      </FeatureWrapper>

      {
        !get(feature, 'isCommentsHidden') && (
          <>
            <CommentsHeader size="middle">
              <Icon icon="comment" />
              {editMode ?
                <FieldComments
                  name="commentsTitle"
                  onChange={formikPost.handleChange}
                  value={formikPost.values.commentsTitle}
                /> : <CommentsHeaderTitle>{get(feature, 'commentsTitle', 'Comments')}</CommentsHeaderTitle>
              }
            </CommentsHeader>

            {!editMode && (
              <CommentBlock>
                <Form onSubmit={formik.handleSubmit}>
                  <CommentArea
                    type="textarea"
                    placeholder="Your comment here"
                    rows={1}
                    name="comment"
                    onChange={formik.handleChange}
                    value={formik.values.comment}
                    required
                  />
                  <Button type="submit" view="primary">
                    <Icon icon="faReply" />
                    &nbsp; Reply
                  </Button>
                </Form>
              </CommentBlock>
            )}
          </>
        )
      }

      {get(feature, 'comments') && !get(feature, 'isCommentsHidden') && (
        <CommentsList>
          {feature.comments.map(comment =>
            (isAdmin || comment.is_answer || !get(feature, 'isCommentsHiddenForUsers') || comment.user.id === userId) && (
            <CommentWrapper
              key={comment.id}
              isLarge
              isAnswer={comment.is_answer}
              time={comment.createdAt}
              name={comment.user.name}
              text={comment.text}
              canEdit={comment.user.id === userId || isAdmin}
              isAdmin={isAdmin}
              onMarkAnswered={() =>
                toggleIsAnswered(uuid, comment.id, !comment.is_answer)
              }
              onDelete={() => deleteComment(uuid, comment.uuid)}
            />
          ))}
        </CommentsList>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Card)`
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 44px;
  box-sizing: border-box; // todo change it across whole app
  ${mq({
    width: [null, null, '970px'],
    padding: ['16px', null, '24px 100px'],
  })};
`;

const Actions = styled('div')`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  margin-bottom: 26px;
`;

const StatusWrapper = styled(Status)`
  margin-right: 16px;
  ${mq({
    marginTop: ['8px', 0],
    marginBottom: ['8px', 0],
  })};
`;

const SettingsButton = styled(IconButton)`
  font-size: 20px;
  color: ${({ theme }) => theme.color.lightGrey};
`;

const Title = styled('h1')`
  margin-bottom: 36px;
  word-break: break-word;
  font-size: 30px;
`;

const FieldTitle = styled(Field)`
  input {
    font-size: 30px;
    font-weight: bold;
    border: none;
    margin-bottom: 8px;
  }
`;

const Meta = styled(Space)`
  margin-bottom: 24px;
`;

const Description = styled('p')`
  margin: 0;
  word-break: break-word;
`;

const FeatureWrapper = styled('div')`
  margin-bottom: 56px;
`;

const Profile = styled('span')`
  font-weight: 500;
  white-space: normal;
  word-break: break-word;
`;

const Time = styled('time')`
  color: ${({ theme }) => theme.color.lightGrey};
`;

const CommentsHeader = styled(Space)`
  font-size: 18px;
  margin-bottom: 32px;
  align-items: center;
`;

const CommentsHeaderTitle = styled('span')`
  margin-bottom: 4px;
`;

const FieldComments = styled(Field)`
  input {
    font-size: 18px;
  }
`;

const CommentBlock = styled('div')`
  display: flex;
  align-items: flex-end;
  margin-bottom: 28px;
`;

const Form = styled('form')`
  display: flex;
  width: 100%;
`;
const CommentArea = styled(Field)`
  flex: 1;
  margin-right: 36px;
  font-size: 16px;
  resize: none;
`;

const CommentsList = styled('ul')`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

const CommentWrapper = styled(Comment)`
  margin-bottom: 16px;
`;

const SettingsBlock = styled(Space)`
  display: flex;
  align-items: center;
  margin-left: auto;
`