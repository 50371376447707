import { createElement } from 'react';
import createRouter from 'router5';
import loggerPlugin from 'router5/plugins/logger';
import browserPlugin from 'router5/plugins/browser';

import { MainContainer, Container404 } from './app';
import { TokenContainer } from 'app/auth/TokenContainer';
import { Landing } from 'app/landing/Landing';
import { AuthContainer } from 'app/landing/AuthContainer';
import { CreateCompanyContainer } from './app/landing/CreateCompanyContainer';
import { RegisterUserContainer } from './app/landing/RegisterUserContainer';
import { FeaturesWrapper } from 'app/features/Wrapper';
import { ListFeaturesContainer } from 'app/features/ListFeaturesContainer';
import { ArchiveFeaturesContainer } from './app/features/ArchiveFeaturesContainer';
import { FeatureContainer } from 'app/features/FeatureContainer';
import { SettingsContainer } from 'app/settings/SettingsContainer';
import { ResetContainer } from 'app/auth/ResetContainer';
import { LogInContainer } from './app/landing/LogInContainer';
import { ForgotPasswordContainer } from './app/landing/ForgotPasswordContainer';
import { ResetPasswordContainer } from './app/landing/ResetPasswordContainer';
import { PrivacyContainer } from './app/landing/PrivacyContainer';

const commonRoutes = (prefix, baseUrl) => [
  {
    name: prefix,
    path: `${baseUrl}/:project`,
    component: MainContainer,
  },
  {
    name: `${prefix}.token`,
    path: '/token/:token',
    component: TokenContainer,
  },
  {
    name: `${prefix}.features`,
    path: '/:page',
    component: FeaturesWrapper,
  },
  {
    name: `${prefix}.features.list`,
    path: '/list',
    component: ListFeaturesContainer,
  },
  {
    name: `${prefix}.features.archive`,
    path: '/archive',
    component: ArchiveFeaturesContainer,
  },
  {
    name: `${prefix}.features.one`,
    path: '/:featureId',
    component: FeatureContainer,
  },
];

export const routes = [
  {
    name: 'landing',
    path: '/',
    component: Landing,
  },
  {
    name: 'privacy',
    path: '/privacy',
    component: PrivacyContainer,
  },
  {
    name: 'auth',
    path: '/auth',
    component: AuthContainer,
  },
  {
    name: 'auth.login',
    path: '/login',
    component: LogInContainer,
  },
  {
    name: 'auth.register',
    path: '/register',
    component: RegisterUserContainer,
  },
  {
    name: 'auth.forgot',
    path: '/forgot',
    component: ForgotPasswordContainer,
  },
  {
    name: 'auth.reset',
    path: '/reset/:token',
    component: ResetPasswordContainer,
  },
  {
    name: 'auth.company',
    path: '/company',
    component: CreateCompanyContainer
  },
  {
    name: '404',
    path: '/404',
    component: Container404,
  },
  ...commonRoutes('fullscreen', '/fullscreen'),
  ...commonRoutes('app', ''),
  {
    name: 'app.settings',
    path: '/settings',
    component: SettingsContainer,
  },
  {
    name: 'app.reset',
    path: '/reset/:token',
    component: ResetContainer,
  },
];

export const getComponent = currentRoute => {
  if (currentRoute && currentRoute.name) {
    const { name = '' } = currentRoute;
    const segments = name.split('.');

    const config = segments.reduce(
      (acc, segment) => {
        const segmentPath = `${acc.segmentPath}${acc.segmentPath &&
          '.'}${segment}`;
        const config = routes.find(route => route.name === segmentPath);
        if (config.component) {
          acc.components.push(config.component);
        }
        return { ...acc, segmentPath };
      },
      { components: [], segmentPath: '' }
    );

    const Wrapper = config.components.shift();
    return createElement(
      Wrapper,
      null,
      config.components
        .reverse()
        .reduce(
          (Children, Wrapper) =>
            createElement(Wrapper, { key: currentRoute.meta.id }, Children),
          null
        )
    );
  }
  return null;
};

const scrollToTop = () => ({
  onTransitionSuccess: () => {
    window.scrollTo(0, 0);
  },
});

export const router = createRouter(routes)
  // Plugins
  .usePlugin(loggerPlugin)
  .usePlugin(browserPlugin())
  .usePlugin(scrollToTop);
