import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { settingsActions, settingsSelectors } from './@duck/settings';
import { Button } from '@common/buttons/Button';
import { Field, Heading, Block } from './components';
import { swal, useDispatchHook } from '@common';

export const InterfaceSection = () => {
  const { title, description, background, name, url } = useSelector(settingsSelectors.project);
  const [logoName, setLogoName] = useState(null);
  const [bgName, setBgName] = useState(null);
  const errors = useSelector(settingsSelectors.errors);
  const updateProject = useDispatchHook(settingsActions.updateProject);
  const editProject = useDispatchHook(settingsActions.editProject);

  const validate = useCallback((values) => {
    const errors = {};
    if(values.title) {
      editProject('title', values.title);
    }
    if(values.description) {
      editProject('description', values.description);
    }
    return errors;
  }, [editProject]);

  const formik = useFormik({
    initialValues: {
      name,
      url,
      title,
      description,
      logo: null,
      background,
    },
    onSubmit: ({ name, url, title, description, logo, background }) => {
      const formData = new FormData();
      if (name) {
        formData.append('name', name);
      }
      if(url) {
        formData.append('url', url);
      }
      if (title) {
        formData.append('title', title);
      }
      if (description) {
        formData.append('description', description);
      }
      if (get(logo, 'name')) {
        formData.append('logo', logo);
      }
      if (get(background, 'name')) {
        formData.append('background', background);
      }
      updateProject(formData)
        .then(() => swal.success('Successfully updated', { timer: 1500 }))
        .catch(() => swal.error('Something went wrong'))
    },
    enableReinitialize: true,
    validate,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Heading level={1}>Personalize. Make your service more familiar</Heading>
      <Block direction="vertical">
        <Field
          fullWidth
          label="Company name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.errors.name}
          required
          innerShadow
        /><br/>
        <Field
          fullWidth
          label="Company Website"
          name="url"
          type="url"
          value={formik.values.url}
          onChange={formik.handleChange}
          innerShadow
        />
      </Block>
      <Block
        title="Company logo"
        description="The uploaded logo will appear on the top"
      >
        <Field
          type="file"
          name="logo"
          accept="image/*"
          onChange={(e) => {
            formik.setFieldValue('logo', e.currentTarget.files[0]);
            setLogoName(e.currentTarget.files[0].name);
          }}
          fileName={logoName}
          error={get(errors, 'logo[0]')}
        />
      </Block>

      <Block
        title="Welcome message"
        description="Change the title to make a friendly welcome message for your customer. Make sure that the title enough readable by changing the color. Watch for changes at the top of the page."
        direction="vertical"
      >
        <Field
          label="Hero Title"
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          innerShadow
        /><br/>
        <Field
          label="Hero Description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          innerShadow
        />
      </Block>

      <Block title="Brand background" description="Company background make your page familiar for your customers">
        <Field
          type="file"
          name="background"
          accept="image/*"
          onChange={(e) => {
            formik.setFieldValue('background', e.currentTarget.files[0]);
            setBgName(e.currentTarget.files[0].name);
          }}
          fileName={bgName}
          error={get(errors, 'background[0]')}
        />
      </Block>
      <Button type="submit" view="primary" size="large">Save</Button>
    </Form>
  );
}

const Form = styled('form')`
  display: flex;
  flex-direction: column;
`;
