import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Formik } from 'formik';

import { mq } from 'themes';
import { SharedForm, swal, useDispatchHook } from '@common';
import { Field } from '@common/formControls/Field';
import { Button } from '@common/buttons/Button';
import { useOutsideAlerter } from '@common/clickOutside/clickOutside';
import { authActions } from './index';

export const LoginContainer = ({ handleRegister, handleClose }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => handleClose());

  const login = useDispatchHook(authActions.login);
  const forgot = useDispatchHook(authActions.forgot);
  const [forgotForm, toggleForgotForm] = useState(false);

  const handleSubmit = (values) => {
    if(forgotForm) {
      return forgot(values).then(handleClose);
    }
    return login(values).then(handleClose);
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit}) => (
          <Form title={forgotForm ? 'Forgot password' : 'Login'} onSubmit={handleSubmit}>
            <FieldWrapper
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              innerShadow
              required
            />
            { !forgotForm &&
            <FieldWrapper
              label="Password"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              innerShadow
              required
            />}
            {
              !forgotForm  && (
                <>
                  <Button view="link" onClick={() => toggleForgotForm(true)}>Forgot password?</Button> <br/>
                </>
              )
            }
            <Button type="submit" size="large" view="primary">{forgotForm ? 'Reset' : 'Log In'}</Button>
            <br/>
            {forgotForm ?
              <Button view="link" onClick={() => toggleForgotForm(false)}>Back to log in</Button> :
              <Button view="link" onClick={handleRegister}>Don't have an account? Sign up</Button>
            }
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export const RegisterContainer = ({ handleLogin, handleClose }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => handleClose());

  const register = useDispatchHook(authActions.register);

  const handleSubmit = (values) => {
    if (values.password !== values.confirmPassword) {
      return swal.error('Your password confirmation does not match.');
    }
    if (values.name && values.email && values.password) {
      register(values).then(handleClose);
    } else {
      swal.error('Please fill all required fields');
    }
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form title="Register" onSubmit={handleSubmit}>
            <FieldWrapper
              label="Full name"
              name="name"
              value={values.name}
              onChange={handleChange}
              required
              innerShadow
            />
            <FieldWrapper
              label="Email"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              required
              innerShadow
            />
            <FieldWrapper
              label="Password"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              required
              innerShadow
            />

            <FieldWrapper
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={values.confirmPassword}
              onChange={handleChange}
              required
              innerShadow
            />
            <Button type="submit" size="large" view="primary">Register</Button>
            <br/>
            <Button view="link" onClick={handleLogin}>Already have an account? Log in</Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  position: fixed;
  top: 62px;
  left: 50%;
  transform: translateX(-50%);
  
  @media (min-width: 768px) {
    top: 100px;
    right: 100px;
    left: auto;
    transform: none;
  }
`;

const Form = styled(SharedForm)`
  width: 300px;
  box-shadow: 0 2px 16px rgba(33, 43, 54, 0.1),
    0 31px 41px rgba(33, 43, 54, 0.2);
`;

const FieldWrapper = styled(Field)`
  ${mq({
    marginBottom: ['12px', null, '26px'],
  })}
`;
