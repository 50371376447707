import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';

import { Link } from '@common';
import { useDispatchHook, useRoute } from '@common/hooks';
import { Button } from '@common/buttons/Button';
import { authActions, authSelectors, authThunks } from '../auth';
import Field from './components/Field';
import { SubTitle, Title } from './components/Title';

export const RegisterUserContainer = () => {
  const { previousRoute, route, navigateTo } = useRoute();
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const emailChecked = useSelector(authSelectors.emailChecked);
  const hasAccount = useSelector(authSelectors.hasAccount);

  const checkEmail = useDispatchHook(authActions.checkEmail);
  const register = useDispatchHook(authActions.register);
  const logout = useDispatchHook(authThunks.logout);

  const email = useMemo(() => get(route, 'params.email', ''), [route]);

  useEffect(() => {
    if(previousRoute && previousRoute.name === 'auth.company') {
      return navigateTo('landing');
    }
  }, [previousRoute, navigateTo]);

  useEffect(() => {
    email && !isLoggedIn && checkEmail(email);
  }, [email, isLoggedIn, checkEmail]);

  useEffect(() => {
    logout();
  }, [logout]);
  useEffect(() => {
    if(emailChecked) {
      if(hasAccount) {
        navigateTo('auth.login');
      }
    }
  }, [emailChecked, hasAccount, navigateTo]);

  const handleSubmit = useCallback(
    async (values) => {
      if(emailChecked) {
        await register(values);
        navigateTo('auth.company');
      } else {
        checkEmail(values)
      }
    }, [emailChecked, register, checkEmail, navigateTo]
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>{'Register User | The Beyond Feedback'}</title>
      </Helmet>
      <Title>Register</Title>
      <SubTitle>Already have an account? <Link to="auth.login">Log in</Link></SubTitle>
      <Formik
        initialValues={{
          email,
          name: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <Field
              label="Email"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              required
              autoComplete="off"
              innerShadow
            />

            {emailChecked && (
              <>
                <Field
                  label="Your name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  required
                  innerShadow
                />
                <Field
                  label="Password"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  required
                  innerShadow
                />
                <Field
                  label="Confirm password"
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  required
                  innerShadow
                />
              </>
            )}
            <Button type="submit" view="primary" size="large">Register</Button>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}