import React from 'react';
import styled from '@emotion/styled/macro';

import { Title } from '@common';

export default ({ title, description, direction, children }) => (
  <Block>
    {title && <Title level={3}>{title}</Title>}
    {description && <Description>{description}</Description>}
    <Wrapper direction={direction}>
      {children}
    </Wrapper>
  </Block>
);

const Block = styled('div')`
  padding-bottom: 20px;
  margin-bottom: 32px;
  border-bottom: 1px solid #EEF0F1;
`;

const Description = styled('p')`
  font-size: 14px;
  line-height: 1.4;
  margin: 0 0 16px;
  color: ${({ theme }) => theme.color.grey};
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: ${({ direction }) => direction === 'vertical' ? 'column' : 'row'};
`