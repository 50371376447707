import kebabCase from 'lodash/kebabCase';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import { reservedWords } from './reservedWords';

export const createSlug = phrase => kebabCase(toLower(phrase));

export const checkForSlugErrors = (name) => {
  if(name.length <= 4) return 'Too short';
  if(includes(reservedWords, createSlug(name))) return 'This is a reserved name. Try another';
}