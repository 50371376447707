import React, { useState, createContext } from 'react';

export const AuthModalContext = createContext(null);

export const AuthModalProvider = ({ children }) => {
  const [isLoginOpen, loginOpen] = useState(false);
  const [isRegisterOpen, registerOpen] = useState(false);
  return (
    <AuthModalContext.Provider
      value={{
        login: [isLoginOpen, loginOpen],
        register: [isRegisterOpen, registerOpen],
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
};
