import React from 'react';
import styled from '@emotion/styled';

import { Field } from '@common/formControls/Field';
import { mq } from 'themes';

export default ({ label, type, name, value, onChange, ...rest}) => (
  <FieldWrapper
    label={label}
    type={type}
    name={name}
    value={value}
    onChange={onChange}
    {...rest}
  />
);

const FieldWrapper = styled(Field)`
  font-size: 16px;
  ${mq({
  marginBottom: ['12px', null, '24px'],
})}
`;