import React from 'react';
import styled from '@emotion/styled';

import { Icon } from '@common';

export const Button = ({
  className,
  children,
  icon,
  onClick,
  active,
  type,
  size,
  view,
  disabled,
  center,
  block,
  capitalized,
  ...rest
}) => {
  let Tag;
  switch(view) {
    case 'primary':
      Tag = PrimaryButton;
      break;
    case 'secondary':
      Tag = SecondaryButton;
      break;
    case 'ghost':
      Tag = GhostButton;
      break;
    case 'link':
      Tag = LinkButton;
      break;
    case 'light':
      Tag = LightButton;
      break;
    default:
      Tag = GhostButton;
      break;
  }
  return (
    <Tag
      size={size}
      className={className}
      type={type}
      onClick={onClick}
      active={active}
      disabled={disabled}
      center={center}
      block={block}
      capitalized={capitalized}
      {...rest}
    >
      {icon && <IconWrapper icon={icon} />}
      {children}
    </Tag>
  )
};

const ButtonCore = styled('button')`
  text-align: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  justify-content: ${({ center }) => center ? 'center' : 'space-between'};
  width: ${({ block }) => block ? '100%' : 'fit-content'};
  ${({ capitalized }) => capitalized && 'text-transform: capitalize'};
  font-size: 14px;
  padding: ${({ size }) => size === 'small' ? '4px 12px' : '10px 16px'};
  font-weight: ${({ size }) => size && 500};
  border: 0;
  border-radius: 6px;
`;

const PrimaryButton = styled(ButtonCore)`
  background: ${({ theme }) => theme.color.brand};
  color: white;
`;

const SecondaryButton = styled(ButtonCore)`
  border: 1px solid #EEF0F1;
  background: #F8F9FA;
  color: ${({ theme }) => theme.color.brand};
`;

const GhostButton = styled(ButtonCore)`
  background: transparent;
`;

const LinkButton = styled(ButtonCore)`
  color: ${({ theme }) => theme.color.brand};
  border: none;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  padding: 0;
  outline: none;
`;

const LightButton = styled(ButtonCore)`
  //color: ${({ theme }) => theme.color.black};
`;

const IconWrapper = styled(Icon)`
  margin-right: 16px;
`;

Button.defaultProps = {
  className: null,
  icon: null,
  type: 'button',
  active: false,
  disabled: false,
  size: 'large',
};
