import React, { useEffect } from 'react';
import styled from '@emotion/styled';

export const CopyToClipboard = ({ text, children, ...rest }) => {
  const target = document.createElement("textarea");

  useEffect(() => {
    target.style.position = "absolute";
    target.style.left = "0";
    target.style.top = "-9999px";
    document.body.appendChild(target);
    target.textContent = text;

    return () => {
      target.remove();
    }
  }, [target, text])

  const handleClick = () => {
    target.focus();
    target.setSelectionRange(0, target.value.length);
    document.execCommand('copy');
  }

  return <Wrapper {...rest} onClick={handleClick}>{children}</Wrapper>;
}

const Wrapper = styled('span')``;