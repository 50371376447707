import React from 'react';
import styled from '@emotion/styled';

export const Title = ({ children }) => (
  <TitleStyle>{children}</TitleStyle>
);

export const SubTitle = ({ children }) => (
  <Subtitle>{children}</Subtitle>
)
const TitleStyle = styled('h1')`
  color: ${({ theme }) => theme.beyond.brand};
  font-size: 24px;
  margin: 0 0 32px;
`;

const Subtitle = styled('p')`
  margin: 0 0 32px;
  color: ${({ theme }) => theme.color.lightGrey};
`