import { createAction, handleActions } from 'redux-actions';
import { Map, fromJS } from 'immutable';
import get from 'lodash/get';
import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import api from 'api';
import localForage from 'localforage';
import { createSelector } from 'reselect';

const initialState = Map({
  project: Map({
    name: '',
    title: '',
    description: '',
    url: '',
    logo: '',
    background: '',
  }),
  pages: Map(),
  errors: null,
  cookieConsentDismissed: undefined,
});

export const reducer = handleActions(
  {
    AUTH_SET_CONFIG: (state, { payload }) =>
      state.set('project', fromJS(payload.project)),
    SETTINGS_CONFIG_INIT_FULFILLED: (state, { payload }) =>
      state.set('project', fromJS(omit(payload.project, ['pages'])))
        .set('pages', fromJS(keyBy(get(payload, 'project.pages'), 'slug'))),
    AUTH_CREATE_ACCOUNT_FULFILLED: (state, { payload }) =>
      state.set('project', fromJS(payload.project)),
    SETTINGS_EDIT_PROJECT: (state, { payload, meta }) =>
      state.updateIn(['project', meta], () => payload),
    SETTINGS_UPDATE_PROJECT_FULFILLED: (state, { payload }) =>
      state.update('project', () => fromJS(payload)),
    SETTINGS_UPDATE_PROJECT_REJECTED: (state, { payload }) =>
      state.set('errors', fromJS(get(payload, 'errors'))),
    SETTINGS_DISMISS_COOKIES_FULFILLED: (state, { payload }) =>
      state.set('cookieConsentDismissed', !!payload),
  },
  initialState
);

export const settingsActions = {
  editProject: createAction(
    'SETTINGS_EDIT_PROJECT',
    (key, value) => value,
    key => key,
  ),
  updateProject: createAction('SETTINGS_UPDATE_PROJECT', async project => {
    const payload = await api.post('project/update', project);
    await localForage.setItem('project', payload);
    return payload;
  }),
  init: createAction('SETTINGS_CONFIG_INIT', async token => {
    const payload = await api.post('init', { token });
    await localForage.setItem('project', payload.project);
    return payload;
  }),
  dismissCookies: createAction('SETTINGS_DISMISS_COOKIES',
    async value => {
      await localForage.setItem('cookieConsentDismissed', value);
      return value;
    }),
};

const project = createSelector(
  state => state.settings.get('project'),
  project => project && project.toJS()
);

const makeCurrentPageUUIDSelector = () => createSelector(
  (state, pageSlug) => state.settings.getIn(['pages', pageSlug, 'uuid']),
  page => page,
);

const currentPage = createSelector(
  state => state.router.route,
  state => state,
  (route, state) => {
    const pages = state.settings.getIn(['pages', get(route, 'params.page')]);
    return pages && pages.toJS();
  }
);

const currentPageUUID = createSelector(
  state => state.router.route,
  state => state,
  (route, state) => state.settings.getIn(['pages', get(route, 'params.page'), 'uuid']),
);

const currentPageSlug = createSelector(
  state => state.router.route,
  state => state,
  (route, state) => state.settings.getIn(['pages', get(route, 'params.page'), 'slug']),
);

const firstPageSlug = createSelector(
  state => state.settings.get('pages').keySeq().first(),
  slug => slug,
);

const token = createSelector(
  state => state.router.route,
  route => get(route, 'params.project', ''),
);

const errors = createSelector(
  state => state.settings.get('errors'),
  errors => errors && errors.toJS()
);

const showCookies = createSelector(
  state => state.settings.get('cookieConsentDismissed'),
  cookieConsentDismissed => !cookieConsentDismissed,
);

export const settingsSelectors = {
  project,
  makeCurrentPageUUIDSelector,
  currentPage,
  currentPageUUID,
  currentPageSlug,
  firstPageSlug,
  token,
  errors,
  showCookies,
};
