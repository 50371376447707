import React from 'react';
import styled from '@emotion/styled';

import { mq } from 'themes';

export const Container = ({ className, as, center, children }) => (
  <Wrapper as={as} center={center} className={className}>{children}</Wrapper>
);

const Wrapper = styled('div')`
  display: flex;
  justify-content: ${({ center }) => center && 'center'};
  ${mq({
    padding: ['0 16px', null, 'unset'],
  })};

  @media (min-width: 1200px) {
      width: 1170px;
      margin-left: auto;
      margin-right: auto;
    }
`;