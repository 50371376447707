import React from 'react';
import styled from '@emotion/styled';

export const Title = ({ level, className, children}) => {
  const Tag = `h${level}`;
  return (
    <Heading as={Tag} className={className} level={level}>{children}</Heading>
  )
}

const fontSizes = {
  1: '24px',
  2: '',
  3: '16px',
};
const Heading = styled('h3')`
  font-size: ${({ level }) => fontSizes[level]};
  margin-bottom: .5em;
`