import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import FlipMove from 'react-flip-move';
import styled from '@emotion/styled';

import { BreadCrumbs, BreadCrumb, useRoute, useDispatchHook } from '@common';
import { Container } from '@common/grid/Container';
import { Hero } from '@common/hero/Hero';
import { Link } from '@common/link/Link';
import { featuresActions, featuresSelectors } from 'app/features';
import { settingsSelectors } from 'app/settings/@duck/settings';
import { Feature } from './components';

export const ArchiveFeaturesContainer = () => {
  const { isFullScreen } = useRoute();
  const projectToken = useSelector(settingsSelectors.token);
  const { title, description, background } =  useSelector(settingsSelectors.project);
  const featuresList = useSelector(featuresSelectors.featuresList);
  const fetchFeatures = useDispatchHook(featuresActions.fetchFeatures);
  const archiveList = useMemo(() => featuresList.filter(({ isArchived }) => isArchived), [featuresList]);
  const currentPageUUID = useSelector(settingsSelectors.currentPageUUID);

  useEffect(() => {
    projectToken && currentPageUUID && fetchFeatures(currentPageUUID);
  }, [projectToken, fetchFeatures, currentPageUUID]);

  return (
    <>
      {
        !isFullScreen && (
          <Hero
            title={title}
            description={description}
            background={background}
          />
        )
      }
      <Main>
        <FeaturesWrapper>
          <BreadCrumbs>
            <BreadCrumb>
              <Link to="features.list">Home</Link>
            </BreadCrumb>
            <BreadCrumb>Archive</BreadCrumb>
          </BreadCrumbs>
          <Title>Archive</Title>
          <List typeName="ul">
            {archiveList.map(feature => (
              <Feature key={feature.id} uuid={feature.uuid} />
            ))}
          </List>
        </FeaturesWrapper>
      </Main>
    </>
  );
};

const Main = styled(Container)`
  margin-top: 24px;
  max-width: 770px;
`;

const FeaturesWrapper = styled('div')`
  flex: 1;
`;

const Title = styled('h1')`
  font-size: 24px;
  margin-bottom: 16px;
`;

const List = styled(FlipMove)`
  display: flex;
  flex-direction: column;
`;
