export const reservedWords = [
  'register',
  'registration',
  'login',
  'settings',
  'profile',
  'account',
  'archive',
  'feature',
  'features',
  'support',
  'help-center',
  'admin',
  'company',
  'thebeyond',
  'beyond',
  'arthur',
  'esttq',
  'repititr',
  'hlopin',
  'aartrainey',
  'fullscreen',
  'privacy',
  'privacy-policy',
  'privacypolicy',
  'terms',
  'conditions',
  'security',
  'price',
];
