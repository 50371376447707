import { toastr } from 'react-redux-toastr';

const defaultOptions = {
  position: 'top-left',
  transitionIn: 'fadeIn',
  newestOnTop: false,
  preventDuplicates: true,
  timeOut: 3000,
};

const createToastr = (toastrMethod, defaultOptions) => (...args) => {
  let options = {};
  if (typeof args[args.length - 1] === 'object') {
    options = args.pop();
  }
  return toastrMethod(...args, { ...defaultOptions, ...options });
};

const success = createToastr(toastr.success, defaultOptions);
const error = createToastr(toastr.error, defaultOptions);
const info = createToastr(toastr.info, defaultOptions);
const warning = createToastr(toastr.warning, defaultOptions);
const { removeByType, remove } = toastr;

export default {
  success,
  error,
  info,
  warning,
  removeByType,
  remove,
};
