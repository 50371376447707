import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

import { mq } from 'themes';
import { Link } from '@common/link/Link';
import { LogoBeyond } from '@common/LogoBeyond';
import { Icon } from '@common/icons/Icon';
import { Button } from '@common/buttons/Button';
import { useDispatchHook, useRoute } from '@common/hooks';
import { Text } from '@common/typography/Text';
import illustrationSVG from 'assets/landing/illustration.svg';
import illustrationWebP from 'assets/landing/illustration.webp';
import collectSVG from 'assets/landing/collect.svg';
import manageSVG from 'assets/landing/manage.svg';
import prioritizeSVG from 'assets/landing/prioritize.svg';
import personalizeSVG from 'assets/landing/personalize.svg';
import ellipse from 'assets/landing/ellipse.jpg';
import footerBg from 'assets/landing/footer-bg.svg';
import footerAccent from 'assets/landing/footer-accent.svg';
import footerBeta from 'assets/landing/footer-beta.svg';
import feedbackExample from 'assets/landing/feedback_example.svg';
import cookie from 'assets/landing/cookie.svg';
import { useSelector } from 'react-redux';
import { settingsActions, settingsSelectors } from '../settings/@duck/settings';

export const Landing = () => {
  const showCookies = useSelector(settingsSelectors.showCookies);
  const dismissCookies = useDispatchHook(settingsActions.dismissCookies);
  const { navigateTo } = useRoute();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      return navigateTo('auth.register', { email: values.email });
    }
  });

  const handleCookieClick = useCallback(() => dismissCookies(true), [dismissCookies]);

  return (
    <Wrapper>
      <Content>
        <Nav>
          <List>
            <ListItem>
              <NavLink to="auth.login">Login</NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="auth.register">Register</NavLink>
            </ListItem>
          </List>
        </Nav>
        <Hero>
          <LogoBeyond/>
          <Title>Feedback Management</Title>
          <Subtitle>Collect and manage customer reviews.</Subtitle>
          <Subtitle isBold>One place for all ideas.</Subtitle>
          <CTA to="auth.register">Get started for free</CTA>
          <Illustration>
            <source srcSet={illustrationWebP} type="image/webp" />
            <img srcSet={illustrationSVG} alt="Combine all the ideas in one place with The Beyond - Feedback management tool" />
          </Illustration>
        </Hero>

        <FeaturesHeader>
          <FeaturesTitle>Understand what your audience needs</FeaturesTitle>
          <FeaturesSubtitle>Connect to your audience,<br/> view the growth of the voices,<br/> manage ideas - <strong>all
            in one place</strong>.</FeaturesSubtitle>
        </FeaturesHeader>
        <Features>
          <Feature>
            <Image>
              <img src={collectSVG} alt="Collect all feedback in one place with The Beyond"/>
            </Image>
            <FeatureContent>
              <Heading>
                Collect all your feedback.
                <Ellipse/>
              </Heading>
              <Description>Combining responses to your work or business in one place will help you to see the big
                picture.</Description>
            </FeatureContent>
          </Feature>

          <Feature>
            <Image>
              <img src={manageSVG} alt="Manage customer ideas with The Beyond"/>
            </Image>
            <FeatureContent>
              <Heading>Manage customer ideas.<Ellipse/></Heading>
              <Description>The easy solution to specify and track feedback statuses. Create a roadmap and deliver
                faster.</Description>
            </FeatureContent>
          </Feature>

          <Feature>
            <Image>
              <img src={prioritizeSVG} alt="Prioritize features with The Beyond"/>
            </Image>
            <FeatureContent>
              <Heading>Prioritize. Follow the audience's voice.<Ellipse/></Heading>
              <Description>Work on the most popular tasks and make decisions your audience asks for.</Description>
            </FeatureContent>
          </Feature>

          <Feature>
            <Image>
              <img src={personalizeSVG} alt="Personalize interface with your brand at The Beyond"/>
            </Image>
            <FeatureContent>
              <Heading>Personalize. Make your service more familiar.<Ellipse/></Heading>
              <Description>Work in your style. Change interface for the great experience.</Description>
            </FeatureContent>
          </Feature>
        </Features>
        <TryBlock>
          <div>
            <Copy>
              <Heading>Still not convinced?</Heading>
              <Description>Submit your idea to us and we will fit your needs.<br/> Vote and comment to help us be more
                useful for you.</Description>
            </Copy>
            <CTA
              to="app.features.list"
              params={{ project: 'app', page: 'features' }}
            >
              See how it works
              <CTAIcon icon="longArrow"/>
            </CTA>
          </div>
          <FeedbackExample src={feedbackExample} alt="Give us an idea how can we be helpful for you"/>
        </TryBlock>
      </Content>
      <Footer>
        <FooterTitle>
          The beta version is free.<br/>
          Improve our service with us <br/>
          and get a discount in the future.
        </FooterTitle>
        <Subtitle>We grow with our customers</Subtitle>
        <form onSubmit={formik.handleSubmit}>
          <Subtitle htmlFor="email" as="label" isBold>Let's start together</Subtitle>
          <Field id="email" placeholder="Your email" value={formik.values.email} onChange={formik.handleChange} name="email" />
          <CTAButton color="black" type="submit">Try it for free now</CTAButton>
        </form>
      </Footer>
      <FooterNav>
        <FooterLogo>
          <LogoBeyond/>
          <Copyright>&copy; 2020 All rights reserved</Copyright>
        </FooterLogo>
        <div>
          <Link to="privacy" color="black">Privacy Policy</Link>
          <Link url="mailto:team@thebeyond.io" color="black">Contact us</Link>
        </div>
        <div>
          <Link url="https://fb.com/theBeyondHQ" target="_blank" color="black">Facebook</Link>
        </div>
      </FooterNav>
      {showCookies &&
      <CookiesAlert onClick={handleCookieClick}>
        <Cookie src={cookie} />
        <Text isBold>Cookie Notice.</Text>&nbsp;
        <Text>We use website cookies to provide a better user experience to you. Read more about our <Text isBold>
          <Link to="privacy" color="black">Privacy Policy.</Link>
        </Text></Text>
        <CookieClose icon="close" />
      </CookiesAlert>}
    </Wrapper>
  )
};

const Wrapper = styled('div')`
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial sans-serif;
  color: ${({ theme }) => theme.beyond.black};
  font-size: 18px;
`;

const Content = styled('main')`
  ${mq({
    width: [null, null, null, '1130px'],
    padding: ['36px 16px 0', '100px 40px 0', null, '50px 36px'],
    margin: ['0 0 80px', '0 0 120px', '0 auto'],
    overflow: ['hidden', null, null, 'unset'],
  })};
`;

const Nav = styled('nav')`
  ${mq({
    marginBottom: ['16px', '32px'],
  })};
`;

const List = styled('ul')`
  display: flex;
  list-style: none;
  ${mq({
    justifyContent: ['center', 'flex-end'],
  })};
`;
const ListItem = styled('li')`
  margin: 0 14px;
`;
const NavLink = styled(Link)`
  color: ${({ theme }) => theme.color.grey};
  font-size: 18px;
  font-weight: bold;
`;

const Hero = styled('header')`
  position: relative;
  ${mq({
    textAlign: ['center', null, 'left'],
    marginBottom: ['32px', null, '160px', '326px'],
  })};
`;

const Title = styled('h1')`
  ${mq({
    fontSize: ['42px', '48px'],
    marginTop: ['28px', '48px'],
    marginBottom: ['28px', '14px'],
    lineHeight: ['1.18', '1.3']
  })};
`;
const Subtitle = styled('p')`
  line-height: 1.3;
  font-weight: ${({ isBold }) => isBold && 'bold'};
  margin-top: 0;
  margin-bottom: 8px;
  ${mq({
    fontSize: ['22px', '24px'],
    padding: ['0 32px', '0'],
  })};
  
  &:nth-of-type(2) {
    margin-bottom: 34px;
  }
`;

const ctaButton = css`
  font-weight: bold;
  color: white;
  border-radius: 10px;
  line-height: 1.5;
  align-items: center;
  ${mq({
    display: ['block', 'inline-flex'],
    padding: ['16px', '12px 60px'],
    fontSize: ['18px', '20px', '24px'],
  })};
`;

const CTA = styled(Link)`
  ${ctaButton};
  background-color: ${({ theme }) => theme.beyond.brand};
`;

const CTAButton = styled(Button)`
  ${ctaButton};
  background-color: ${({ theme }) => theme.beyond.black};
  ${mq({
    width: ['100%', 'auto'],
  })};
`;

const CTAIcon = styled(Icon)`
  ${mq({
    marginLeft: ['12px', '20px'],
  })};
`;

const Illustration = styled('picture')`
  display: block;
  right: 0;
  z-index: -1;
  ${mq({
    marginTop: ['36px', 0],
    position: ['relative', null, 'absolute'],
    width: [null, null, '700px', '1024px'],
    top: [null, null, '30px', 0],
    right: [null, null, '-60px'],
  })};
`

const Features = styled('section')``;

const FeaturesHeader = styled('header')`
  ${mq({
    textAlign: ['center', 'left'],
    width: [ '100%', '480px'],
  })};
`;
const FeaturesTitle = styled('h2')`
  ${mq({
    fontSize: [ '30px', '36px'],
  })};
  color: ${({ theme }) => theme.beyond.brand};
  margin-bottom: 34px;
  line-height: 1.3;
`;
const FeaturesSubtitle = styled('p')`
  margin: 0;
  line-height: 1.8;
  ${mq({
    fontSize: ['16px', '20px'],
  })};
`
const FeatureContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.8;
  ${mq({
    padding: [null, '0 40px'],
  })};
`;

const Image = styled('picture')`
  ${mq({
    flex: [null, null, '0 0 50%'],
  })};
`;

const Ellipse = styled('span')`
  position: absolute;
  width: 178px;
  height: 172px;
  background: url(${ellipse}) no-repeat;
  background-size: cover;
  top: -30px;
  left: -60px;
  z-index: -1;
  transition: transform 2s ease-out;
`;

const Heading = styled('h3')`
  position: relative;
  ${mq({
    fontSize: ['32px', '36px'],
  })};
  line-height: 1.3;
  
  &:hover {
    ${Ellipse} {
      transform: translate3d(-20px, -30px, 0);
    }
  }
`;
const Description = styled('p')`
  ${mq({
    fontSize: ['16px', '18px'],
  })};
`;

const Feature = styled('article')`
  display: flex;
   ${mq({
    flexDirection: ['column', null, 'row'],
    textAlign: ['center', 'left'],
    marginBottom: ['40px', null, '0'], 
  })};
  
  &:nth-of-type(even) {
    ${Image} {
     ${mq({
        order: [ null, null, 1],
      })};
    }
    ${FeatureContent} {
      ${mq({
        order: [ null, null, 0],
        padding: ['0 20px', '0 70px']
      })};
    }
  }
  
  &:nth-of-type(odd) {
    ${Image} {
      ${mq({
        marginLeft: [null, null, '-58px'],
      })};
    }
  }
  
  &:nth-of-type(2) {
    ${Heading} {
      &::after {
        transform: rotate(60deg);
      }
    }
  }
  
  &:nth-of-type(3) {
    ${Heading} {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }
  
  &:nth-of-type(3) {
    ${Heading} {
      &::after {
        transform: rotate(78deg);
      }
    }
  }
`;

const TryBlock = styled('section')`
  display: flex;
  background: #F8F9FA;
  border-radius: 20px;
  line-height: 1.8;
  ${mq({
    flexDirection: ['column', null, 'row'],
    padding: ['30px 36px 0', null, '70px 36px 0 70px'],
    textAlign: ['center', null, 'left'],
    marginBottom: ['60px'],
  })};
`;

const Copy = styled('div')`
  margin-bottom: 36px;
`;

const FeedbackExample = styled('img')`
  ${mq({
    marginLeft: ['-20px', null, '-40px'],
    width: [null, null, '60%'],
    flex: [null, null, '0 0 60%'],
  })};
`

const Footer = styled('footer')`
  position: relative;
  text-align: center;
  background: url(${footerBg}) no-repeat top;
  background-size: cover;
  ${mq({
    padding: [ '80px 16px 20px', '120px 16px', '215px 16px 160px'],
  })};
  z-index: 0;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    
    height: 100%;
    background: url(${footerBeta}) no-repeat 0 0;
    background-size: contain;
    z-index: -1;
    ${mq({
      width: ['50%', null, '30%'],
      top: ['-20%', '-25%', '-15%'],
    })};
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${footerAccent}) no-repeat bottom;
    z-index: -1;
    ${mq({
      backgroundSize: ['contain', null, 'cover'],
    })};
  }
`;

const FooterTitle = styled('h2')`
  ${mq({
    fontSize: [ '26px', '40px'],
  })};
  line-height: 1.3;
  color: white;
  margin-bottom: 36px;
`;

const Field = styled('input')`
  display: block;
  ${mq({
    width: ['100%', null, '670px'],
  })};
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #323950;
  font-size: 18px;
  margin: 32px auto;
  text-align: center;
  box-sizing: border-box;
`;

const FooterNav = styled('nav')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }) => theme.beyond.brand};
  ${mq({
    flexDirection: ['column', 'row'],
    padding: ['16px', null, '40px 0'],
  })};
  
  a {
    margin-right: 32px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  & > div {
    ${mq({
      marginTop: ['12px', 0],
    })};
  }
`;

const FooterLogo = styled('div')`
  display: flex;
  flex-direction: column;
  ${mq({
    order: [1, 0],
    textAlign: ['center', 'initial'],
    marginTop: ['24px', 0],
  })};
`;

const Copyright = styled('span')`
  margin: 12px 0;
`;

const CookiesAlert = styled('div')`
  position: fixed;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 12px 32px;
  background: rgba(238, 240, 241, 0.9);
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  ${mq({
    flexDirection: ['column', 'row'],
    width: [null, null, null, '1130px'],
  })};
`;

const Cookie = styled('img')`
  width: 20px;
  margin-right: 16px;
  ${mq({
    marginBottom: ['8px', 0],
  })};
`

const CookieClose = styled(Icon)`
  margin-left: auto;
  ${mq({
    position: ['absolute', 'relative'],
    top: ['14px', 'unset'],
    right: ['12px', 'unset']
  })};
`
