import { combineReducers } from 'redux';
import { router5Reducer as router } from 'redux-router5';
import { reducer as auth } from 'app/auth';
import { reducer as features } from 'app/features';
import { reducer as settings } from 'app/settings/@duck/settings';

export default combineReducers({
  router,
  auth,
  features,
  settings,
});
