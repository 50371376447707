import React, { useEffect, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import get from 'lodash/get';

import { mq } from 'themes';
import { Icon, useDispatchHook } from '@common';
import { Link } from '@common/link/Link';
import { useRoute } from '@common/hooks';
import { AuthModalContext } from '@common/utils/authModalContext';
import { LoginContainer, RegisterContainer } from 'app/auth/AuthModals';
import { authSelectors, authThunks } from 'app/auth';
import { settingsSelectors } from 'app/settings/@duck/settings';

export const MainContainer = ({ children }) => {
  const { route, navigateTo, isFullScreen } = useRoute();

  const project = useSelector(settingsSelectors.project);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const projectToken = useSelector(settingsSelectors.token);
  const firstPageSlug = useSelector(settingsSelectors.firstPageSlug);
  const logout = useDispatchHook(authThunks.logout);

  const logo = get(project, 'logo');

  const {
    login: [isLoginOpen, loginOpen],
    register: [isRegisterOpen, registerOpen],
  } = useContext(AuthModalContext);

  useEffect(() => {
    if(projectToken && firstPageSlug && (route.name === 'app' || route.name === 'fullscreen')) navigateTo('features.list', { page: firstPageSlug });
  }, [navigateTo, route, projectToken, firstPageSlug]);

  useEffect(() => {
    project && project.isPrivate && !isAdmin && navigateTo('landing');
  }, [project.isPrivate, isAdmin]);

  const handleLoginOpen = () => {
    loginOpen(true);
    registerOpen(false);
  };

  const handleRegisterOpen = () => {
    registerOpen(true);
    loginOpen(false);
  };

  const handleLogout = useCallback(() => logout(), [logout]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${get(project, 'name')} | The Beyond Feedback` }</title>
      </Helmet>
      <ModalWrapper>
        { isLoginOpen && <LoginContainer handleRegister={handleRegisterOpen} handleClose={() => loginOpen(false)} />}
        { isRegisterOpen && <RegisterContainer handleLogin={handleLoginOpen} handleClose={() => registerOpen(false)} />}
      </ModalWrapper>
      <Wrapper>
        {!isFullScreen && (
          <Header>
            <Nav>
              <NavLeft>
                <li>
                  <LogoLink
                    to="features.list"
                    title="Feedback Center"
                    params={{ page: firstPageSlug || 'features' }}
                  >
                    {logo && <img src={logo} alt="Logo" />}
                    Feedback
                  </LogoLink>
                </li>
              </NavLeft>

              <NavRight>
                {
                  !isLoggedIn ? (
                    <>
                      <li>
                        <NavButton onClick={handleLoginOpen}>Login</NavButton>
                      </li>
                      <li>
                        <NavButton onClick={handleRegisterOpen}>Register</NavButton>
                      </li>
                    </>
                  ) : (
                    <>
                      {
                        isAdmin && (
                          <li>
                            <NavLink to="settings">
                              <NavIcon icon="edit" />
                              Settings
                            </NavLink>
                          </li>
                        )
                      }
                      <li>
                        <NavButton onClick={handleLogout}>
                          <NavIcon icon="logout" />
                          Logout
                        </NavButton>
                      </li></>
                  )
                }
              </NavRight>
            </Nav>
          </Header>
        )}
        <Content isFullScreen={isFullScreen}>{children}</Content>
      </Wrapper>
    </React.Fragment>
  );
};

const ModalWrapper = styled('div')`
  position: relative;
  z-index: 10;

  @media (min-width: 1200px) {
    width: 1170px;
    margin: 0 auto;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Header = styled('header')`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 100;
  ${mq({
    height: ['60px', '88px'],
  })};
`;

const Nav = styled('nav')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mq({
    width: ['100%', null, '1170px'],
    padding: ['4px 12px', '14px 20px'],
    marginLeft: [null, null, 'auto'],
    marginRight: [null, null, 'auto'],
  })};
`;

const NavLeft = styled('ul')`
  height: 100%;
  li {
    height: 100%;
  }
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ isFullScreen }) => !isFullScreen && mq({
    marginTop: ['60px', '88px'],
  })};
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.color.black};
  text-decoration: none;
  ${mq({
    fontSize: ['16px', '20px'],
  })}

  img {
    vertical-align: middle;
    margin-right: 12px;
    width: auto;
    max-height: 100%;
    ${mq({
      maxWidth: ['50px', '64px'],
    })}
  }

  sup i {
    ${mq({
      display: ['none', 'inline'],
    })}
  }
`;

const NavRight = styled('ul')`
  display: flex;
  align-items: center;

  li {
    ${mq({
      padding: ['0', '0 16px'],
      textAlign: ['right', 'center'],
    })};
  }
`;

const navButtonStyle = ({ theme }) => css`
  color: ${theme.color.lightGrey};
  &:hover {
    color: ${ theme.color.brand};
  }
`;
const NavLink = styled(Link)`
  ${navButtonStyle};
`;

const NavIcon = styled(Icon)`
  margin-right: 10px;
`
const NavButton = styled('button')`
  border: none;
  background: transparent;
  font-size: 16px;
  ${navButtonStyle};
`;

