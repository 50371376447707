import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

import { Rating, useDispatchHook } from '@common';
import { AuthModalContext } from '@common/utils/authModalContext';
import { authSelectors } from 'app/auth';
import { featuresActions } from 'app/features/@duck/features';

export const Voting = ({ className, feature, showOnlyVote }) => {
  const { uuid, voted, rating, isVotingResultsPrivate } = feature;
  const userId = useSelector(authSelectors.userId);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const voteUp = useDispatchHook(featuresActions.voteUp);
  const voteDown = useDispatchHook(featuresActions.voteDown);

  const { login: [, loginOpen] } = useContext(AuthModalContext);

  const handleVoteUp = useCallback(() => {
    if(userId) {
      voteUp(uuid);
    }
    else {
      loginOpen(true);
    }
  }, [userId, uuid, voteUp, loginOpen]);

  const handleVoteDown = useCallback(() => {
    if(userId) {
      voteDown(uuid)
    }
    else {
      loginOpen(true);
    }
  }, [userId, uuid, voteDown, loginOpen]);

  return (
    <Rating
      className={className}
      voteUp={handleVoteUp}
      voteDown={handleVoteDown}
      voted={voted}
      showOnlyVote={showOnlyVote}
      rating={(isAdmin || !isVotingResultsPrivate) && rating}
    />
  );
};
