import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Formik } from 'formik';

import { useDispatchHook, useRoute } from '@common/hooks';
import { SharedForm, swal } from '@common';
import { Field } from '@common/formControls/Field';
import { Container } from '@common/grid/Container';
import { authActions } from './@duck/auth';
import { useSelector } from 'react-redux';
import { settingsSelectors } from '../settings/@duck/settings';

export const ResetContainer = () => {
  const { navigateTo, route } = useRoute();
  const token = route.params.token;
  const firstPageSlug = useSelector(settingsSelectors.firstPageSlug);

  const reset = useDispatchHook(authActions.reset);
  const handleSubmit = useCallback(({ email, password, confirmPassword }) => {
    if(password === confirmPassword) {
      reset({ email, password, confirmPassword, token}).then(() => {
        swal.success('Password successfully changed');
        firstPageSlug && navigateTo('features.list', { page: firstPageSlug});
      });
    }
    else {
      swal.error('Password mismatch');
    }
  }, [token, firstPageSlug, navigateTo, reset]);

  const handleValidation = ({ password }) => {
    const errors = {};

    if(password.length < 8) {
      errors.password = 'Password should be at least 8 characters long';
    }
    return errors;
  }
  return (
    <Container center>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={handleSubmit}
        validate={handleValidation}
      >{
        ({ values, handleChange, handleSubmit, errors }) => (
          <Form title="Reset Password" cta="Reset" onSubmit={handleSubmit}>
            <FieldWrapper
              label="Email"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              required
            />
            <FieldWrapper
              label="New Password"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password}
              required
            />

            <FieldWrapper
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={values.confirmPassword}
              onChange={handleChange}
              required
            />
          </Form>
        )
      }</Formik>
    </Container>
  );
}

const Form = styled(SharedForm)`
  width: 480px;
`;

const FieldWrapper = styled(Field)`
  margin-bottom: 26px;
`;