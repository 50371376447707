import React from 'react';
import styled from '@emotion/styled';

import { mq } from 'themes';

export const Container404 = () => (
  <Content>
    <h1>404</h1>
  </Content>
);

const Content = styled('div')`
  ${mq({
    width: ['100%', null, '1170px'],
  })};
  margin: 0 auto;
`;