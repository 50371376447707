import * as React from 'react';
import styled from '@emotion/styled/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FaIcon = ({ className, icon, size }) => (
  <Icon className={className} size={size}>
    {icon && <FontAwesomeIcon icon={icon} />}
  </Icon>
);

const Icon = styled('i')`
  font-size: ${({ size }) => (size ? `${size}px` : '')};
`;

FaIcon.defaultProps = {
  className: null,
  name: null,
  size: null,
};
