import React from 'react';

import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

import { Icon } from '..';

export const RatingButton = ({ vote, onClick, isActive }) => {
  switch (vote) {
    case 1:
      return (
        <BtnUp onClick={onClick} active={isActive}>
          <Icon icon="faArrowUp" />
        </BtnUp>
      );
    case 0:
      return (
        <BtnDown onClick={onClick} active={isActive}>
          <Icon icon="faArrowUp" />
        </BtnDown>
      );
    default:
      return null;
  }

}
export const Rating = ({ className, voteUp, voteDown, voted, rating, showOnlyVote }) => (
  <RatingContainer className={className}>
    {
      showOnlyVote ?
        <RatingButton onClick={voteUp} vote={voted} isActive /> :
        <>
          <RatingButton onClick={voteUp} vote={1} isActive={voted === 1} />
          <Score>{rating}</Score>
          <RatingButton onClick={voteDown} vote={0} isActive={voted === 0} />
        </>
    }
  </RatingContainer>
);

const RatingContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const buttons = css`
  border: 1px solid #f1f4f6;
  background: white;
  border-radius: 50%;
  padding: 8px 10px;
  cursor: pointer;
`;

const BtnUp = styled('button')`
  ${buttons};
  color: #88DD8B;
  ${({ active }) => active && `
    color: white;
    background: #88DD8B;
    border: 0;
  `};

  ${RatingContainer} {
    display: none;
  }
`;

const BtnDown = styled('button')`
  ${buttons};
  color: #DD8B88;
  transform: rotate(180deg);
  ${({ active }) => active && `
    color: white;
    background: #DD8B88;
    border: 0;
  `};
`;

const Score = styled('span')`
  padding: 0 16px;
  font-weight: 500;
`;
