import React, { useState, useMemo, forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import { useSelector } from 'react-redux';

import { mq, buttonOverride } from 'themes';
import { Comment, Link, Menu, swal, Icon } from '@common';
import { useDispatchHook } from '@common/hooks';
import { featuresActions, featuresSelectors } from 'app/features';
import { authSelectors } from 'app/auth';
import { Status } from './Status';
import { Voting } from './Voting';
import { Dropdown } from '@common/formControls/Dropdown';
import { Field } from '@common/formControls/Field';
import { useFormik } from 'formik';

export const Feature = forwardRef(({ uuid }, ref) => {
  const [showAnswerField, setAnswerField] = useState(false);
  const makeFeature = useMemo(() => featuresSelectors.makeFeature(), []);
  const feature = useSelector(state => makeFeature(state, uuid));
  const makeFeatureAnswer = useMemo(() => featuresSelectors.makeFeatureAnswer(), []);
  const answer = useSelector(state => makeFeatureAnswer(state, uuid));
  const userId = useSelector(authSelectors.userId);
  const isAdmin = useSelector(authSelectors.isAdmin);

  const deleteFeature = useDispatchHook(featuresActions.deleteFeature);
  const putComment = useDispatchHook(featuresActions.putComment);

  const handleDelete = () => swal
    .fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    })
    .then(({ value }) => {
      if (value) {
        deleteFeature(uuid);
        swal.success('Poof! Your feature has been deleted!');
      }
    });

  const formik = useFormik({
    initialValues: {
      comment: ''
    },
    onSubmit: async (values, { resetForm }) => {
      await putComment(uuid, values.comment, true);
      resetForm();
      setAnswerField(false);
    }
  })

  if(!feature) return null;
  const {
    name,
    description,
    author,
    status,
    isHidden,
    isCommentsHidden,
    isCommentsHiddenForUsers,
    isVotingHiddenForUsers,
  } = feature;

  const menu = (
    <>
      <Menu.Item onClick={() => setAnswerField(true)} icon="reply">Reply</Menu.Item>
      <Menu.Item onClick={handleDelete} icon="delete">Delete</Menu.Item>
    </>
  );
  if (!isAdmin && isHidden) return null;
  const commentsCount = feature.comments.length;
  return (
    <Wrapper ref={ref}>
      <Header>
        <Title to="features.one" params={{ featureId: uuid }}>
          {name}
        </Title>
        <ActionBlock>
          {(author.id === userId || isAdmin) && (
            <Dropdown menu={menu}>
              <DeleteBtn>
                <Icon icon="gear" />
              </DeleteBtn>
            </Dropdown>
          )}
        </ActionBlock>
      </Header>
      <Description>{description}</Description>
      <Footer>
        <LeftSection>
          {author && <Profile>{author.name || author.email}</Profile>}
        </LeftSection>
        <RightSection>
          {commentsCount > 0 && (isAdmin || (!isCommentsHidden && !isCommentsHiddenForUsers)) && (
            <CommentsCount to="features.one" params={{ featureId: uuid }}>
              <CommentIcon icon="comment" />
              {commentsCount}
            </CommentsCount>
          )}
          <Status status={status} featureUUID={uuid} isAdmin={isAdmin} />
          {(isAdmin || !isVotingHiddenForUsers) && <VotingWrapper feature={feature} />}
        </RightSection>
      </Footer>

      {
        showAnswerField &&
        <form onSubmit={formik.handleSubmit}>
          <FieldWrapper
            name="comment"
            onChange={formik.handleChange}
            value={formik.values.comment}
            placeholder="Type your answer here"
            autoFocus
          />
        </form>
      }

      {answer && <Comment isAnswer name={answer.user.name} text={answer.text} />}
    </Wrapper>
  );
});


const Wrapper = styled('li')`
  position: relative;
  ${mq({
    padding: ['16px', '30px 50px 24px'],
  })};
  border: 1px solid #EEF0F1;
  border-radius: 6px;
  margin-bottom: 16px;
  background: white;
`;

const Header = styled('header')`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  
  @keyframes fadeOutAnimation {
    0% {
      background: #05c46b33;
    }
    100% {
      background: white;
    }
  }
`;

const Title = styled(Link)`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.color.black};
  text-decoration: none;
  margin-right: 32px;
  word-break: break-word;
`;

const ActionBlock = styled('div')`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const DeleteBtn = styled('button')`
  ${buttonOverride};
  font-size: 20px;
  color: ${({ theme }) => theme.color.lightGrey};
`;

const Description = styled('p')`
  color: ${({ theme }) => theme.color.grey};
  margin: 0 0 12px;
  line-height: 1.4;
  word-break: break-word;
  white-space: pre-line;
`;

const Footer = styled('div')`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  white-space: nowrap;
  margin-bottom: 16px;
  ${mq({
    alignItems: ['initial', 'center'],
    flexDirection: ['column', 'row'],
  })}
`;

const FieldWrapper = styled(Field)`
  margin-bottom: 16px;
`;

const LeftSection = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  ${mq({
    justifyContent: ['space-between', 'initial'],
    marginBottom: ['8px', 0],
  })};
`;

const RightSection = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  ${mq({
    justifyContent: ['space-between', 'flex-end'],
  })};
`;

const Profile = styled('span')`
  font-weight: 500;
  margin-right: 8px;
  white-space: normal;
  word-break: break-word;
`;

const CommentsCount = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.lightGrey};
  ${mq({
    order: [1, 0],
    marginRight: [null, '30px'],
  })};
`;

const CommentIcon = styled(Icon)`
  margin-right: 10px;
`;

const VotingWrapper = styled(Voting)`
  ${mq({
    order: [1, 0],
    marginLeft: [null, '30px'],
  })};
`;
